/**
 * include stock order create, detail page
 * notes
 *    UPDATETONEXTSTATUS button set show / disabled, please remove / update this if logic rewrite
 *        ngOnInit >>> this.p.queryParams.subscribe(params => {
 *        this.loadDataByHeadId(params['headId'])
 *        this.setDisabled()
 *        btn show >>> item.show = !this.isNew && this.proceedFlag && this.showSearch
 *        this.initUpdateBtnDisabled()
 *        btn disabled >>> updateToNextStatusBtn.disabled = this.getUpdateToNextStatusDisabled();
 *        in many api call will call setUpdateBtnDisabled() to disabled UPDATETONEXTSTATUS button, release when api complete
 */

import { Component, Input, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {action, computed, observable} from "mobx";
import {HttpHelper} from "../../../../util/HttpHelper";
import {CONFIG, INPUT_TYPE, NOTI_TITLE, URLDICT} from "../../../../stores/base/BaseStore";
import {LocalStorageHelper} from "../../../../util/LocalStorageHelper";
import {CommonMethod} from "../../../../util/CommonMethod";
import { MessageService, ConfirmationService } from 'primeng/api';
import { StockCommonService } from 'src/app/service/stock/stock-common.service';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { CommonService } from 'src/app/service/common/common-service';
import { CommonStore } from 'src/app/service/common/common-store-service';
import { BTN_LABEL, ORDERTYPEID, ORDER_CONFIRM_MSG, ORDER_STATUS_UPDATE_OPERATION, PANELBTNTAG, REASON, SEARCH_PERMISSION_CODE, SELECTEDTYPE, STATUSID, TITLE } from '../StockOrderShare';
import { StockOrderService } from 'src/app/service/stock/stock-order.service';
import { TranslateService } from '@ngx-translate/core';
import { TAKE_ADJUSTMENT_TXN_TYPE } from '@/stores/stock/take/TakeShare';
import { PermissionService } from '@/service/common/permission-service';
import { FUNCTION_CODE } from '@/service/common/permission.type';
import { FilterCommonMethod } from '@/util/FilterCommonMethod';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'nz-test',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit, OnDestroy {

  @observable permission:string[]

  @observable _loading = 0;
  @computed get loadingSwitch(){return this._loading};
  set loadingSwitch(e) {
    this._loading = e > 0? e: 0;
  };

  @observable queryPannel: any = {}
  @observable lisOfData: any[] = []

  @observable isVisible: boolean = false

  public selectedChannel
  public selectedChannelDisplay

  items: any[] = []
  adjReasons: any[] = []
  colorAdjReasons: any[] = [];
  _reasonOptions: any[] = [];
  seItems: any[] = []
  conditions: any[] = []
  channels: any[] = []
  channels2: any[] = []
  sechannels: any[] = []
  sechannels2: any[] = []
  orderTypes = []
  status: any[] = []
  statusItem1
  statusItem2
  orderTypeItem
  orderTypeItemLabel
  orderStatus: any[] = []
  orderStatusId:any = 1
  orderStatusObject: any = null;
  channelValue
  channelValue2
  itemValue
  balance
  seItem
  itemDesc
  itemId
  qty
  StockInQty
  creationDate
  updateDate
  remark = ''
  noCartonBox = ''
  sourceTxnRefHeaderNo=''
  quantity
  fromChannel
  toWareHouse
  deliveryNote = ''
  orderNumber = ''
  fromStatus: any[] = []
  fromNatures: any = []
  toStatus: any[] = []
  shopAndWarehouse: any[] = []
  warehouseListForTreeSelectOptions = []
  _btnLabel = BTN_LABEL.CONFIRM
  set btnLabel(e){this._btnLabel = e;}
  get btnLabel(){return this._btnLabel}
  moduleName=''
  buttonCountId
  data:any = {line: []}
  updateStatusData: {orderNumber: string, operation: string, updateType: any, line?: any[]} = {orderNumber: "", operation: "", updateType: ""}
  lineIndex: number = 0
  lineNumber: number = 1 //start from 1 now
  lineData: any[] = []
  headId = null; // set data when ngOnInit router params or submit() submit result
  isTransfered
  STATUSID = STATUSID;
  SELECTEDTYPE = SELECTEDTYPE;
  fileNameList:any[]=[]
  // adjustment field
  internalRemarks=''
  internalReference=''
  netAmount=''
  netAmtQty=0
  sourceTxnType
  today = new Date(new Date().setHours(0,0,0,0))
  ClearDefaultChannel='N'
  updateBy
  createBy
  isActionEnable:boolean
  // setTableColBySelectedType() control which columns display
  cols = [
    {header: 'statusCondition', title: 'Status Condition', width: '200px', _exportField: 'fromStockConditionDesc'},
    {header: 'itemCode', title: 'Item Code', width: '140px', type: 'text'},
    {header: 'item', title: 'Item Description', width: '400px', _exportField: 'itemDesc'},
    // {header: 'itemDesc', title: 'Item Desc', width: '200px'},
    // {header: 'lotNo', title: 'Lot no', width: '100px'},
    {header: 'qty', title: 'Qty', width: '100px'},
    {header: 'qtyActual', title: 'Received Qty', width: '100px', type: 'receiveColumns'},
    {header: 'qtyCancelled', title: 'Cancelled Qty', width: '100px'},
    {header: 'qtyWriteOff', title: 'Write-Off Qty', width: '100px'},
    {header: 'reason', title: 'Reason', width: '200px', _exportField: 'reasonDesc'},
    {header: 'finalReason', title: 'Final Reason', width: '200px', _exportField: 'finalReasonDesc'},
    {header: 'remark', title: 'Remark', width: '200px'},
    {header: 'replenishReference', title: 'Replenish Reference', width: '200px', type: 'text'},
    {header: 'isoNo', title: 'ISO No.', width: '200px', type: 'text'},
    {header: 'deliveryBatchNo', title: 'Delivery Batch No.', width: '200px', type: 'text'},
  ]

  lineCols = [
    {header: 'serialNumber', title: 'Serial'}
  ]
  disabledList = [TITLE.ORDER_NUMBER, TITLE.CREATION_DATE, TITLE.REFERENCE_NUMBER, TITLE.ORDER_STATUS];
  pageIndex: number = 0
  pageSize: number = 10
  totalRecords = 0

  isNew = true
  isReason = false
  saveFlag = false // default false for hide before loaddata
  proceedFlag = false
  editing = false;
  isDraft = false;
  toOptions = [];
  fromOptions = [];
  uamChannelOptions = [];
  get isLineAttachment(){
    return this.selectedType==SELECTEDTYPE.ADJUSTMENT||this.selectedType==SELECTEDTYPE.ADJUSTMENTHKTHOME
  }
  get isUploadAttchment(){
    return this.isEditable&&this.showCreate
  }
  allocateCreateTimer

  reasonMapping = {};
  conditionMapping = {};

  get isEditable(){return this.isNew}
  get isCheckingReceived(){return (
    (
      this.selectedType == SELECTEDTYPE.ASSORTMENT ||
      this.selectedType == SELECTEDTYPE.NOPREPLENISHMENT ||
      this.selectedType == SELECTEDTYPE.CHANNELTRANSFER ||
      this.selectedType == SELECTEDTYPE.CHANNELTRANSFERCOURIER
    ) &&
    ( this.orderStatusId==STATUSID.TRANSFERRED || this.orderStatusId==STATUSID.PARTIAL )
  )}

  get receivePermission(){
    return (
      (
        this.selectedType == this.SELECTEDTYPE.ASSORTMENT && this.orderStatusId == STATUSID.TRANSFERRED &&
        this.permissionService.havePermission(FUNCTION_CODE.SO_TYPE4_RECEIVE)
      ) ||
      (
        this.selectedType == this.SELECTEDTYPE.NOPREPLENISHMENT && this.orderStatusId == STATUSID.TRANSFERRED &&
        this.permissionService.havePermission(FUNCTION_CODE.SO_TYPE24_RECEIVE)
      ) ||
      (
        this.selectedType == this.SELECTEDTYPE.CHANNELTRANSFER && this.orderStatusId == STATUSID.TRANSFERRED &&
        this.permissionService.havePermission(FUNCTION_CODE.SO_TYPE5_RECEIVE)
      ) ||
      (
        this.selectedType == this.SELECTEDTYPE.CHANNELTRANSFERCOURIER && this.orderStatusId == STATUSID.TRANSFERRED &&
        this.permissionService.havePermission(FUNCTION_CODE.SO_TYPE16_RECEIVE)
      )
    )
  }

  get isWarehouseToChannel(){
    return this.selectedType == SELECTEDTYPE.ASSORTMENT
  }

  get showReturn(){
    return false; // should be dm call api to change to return
    // this.selectedType == SELECTEDTYPE.WAREHOUSEHOMED && this.orderStatusId == STATUSID.TRANSFERRED
  }

  get showReject(){
    return (
      this.selectedType == SELECTEDTYPE.ADJUSTMENT &&
      this.orderStatusId == STATUSID.ADJ_REVIEWING
    ) || (
      this.selectedType == SELECTEDTYPE.ADJUSTMENT &&
      this.orderStatusId == STATUSID.ADJ_SUBMITTED
    ) || (
      this.selectedType == SELECTEDTYPE.ADJUSTMENTHKTHOME &&
      this.orderStatusId == STATUSID.ADJ_REVIEWING
    ) || (
      this.selectedType == SELECTEDTYPE.ADJUSTMENTHKTHOME &&
      this.orderStatusId == STATUSID.ADJ_SUBMITTED
    ) || (
      this.selectedType == SELECTEDTYPE.COLORADJUSTMENT &&
      this.orderStatusId == STATUSID.ADJ_REVIEWING
    )
  }

  get showCancel(){
    return (
      [SELECTEDTYPE.ADJUSTMENT,SELECTEDTYPE.ADJUSTMENTHKTHOME,SELECTEDTYPE.COLORADJUSTMENT]
      .includes(this.selectedType)&&
      this.orderStatusId == STATUSID.ADJ_DRAFT
      &&!this.isEditable
    )
  }

  get rejectPermission(){
    return (
      this.selectedType == SELECTEDTYPE.ADJUSTMENT &&
      this.orderStatusId == STATUSID.ADJ_REVIEWING &&
      this.permissionService.havePermission(FUNCTION_CODE.SO_TYPE1_REVIEW)
    ) || (
      this.selectedType == SELECTEDTYPE.ADJUSTMENT &&
      this.orderStatusId == STATUSID.ADJ_SUBMITTED &&
      this.permissionService.havePermission(FUNCTION_CODE.SO_TYPE1_VERIFY)
    ) || (
      this.selectedType == SELECTEDTYPE.ADJUSTMENTHKTHOME &&
      this.orderStatusId == STATUSID.ADJ_REVIEWING &&
      this.permissionService.havePermission(FUNCTION_CODE.SO_TYPE27_REVIEW)
    ) || (
      this.selectedType == SELECTEDTYPE.ADJUSTMENTHKTHOME &&
      this.orderStatusId == STATUSID.ADJ_SUBMITTED &&
      this.permissionService.havePermission(FUNCTION_CODE.SO_TYPE27_VERIFY)
    ) || (
      this.selectedType == SELECTEDTYPE.COLORADJUSTMENT &&
      this.orderStatusId == STATUSID.ADJ_REVIEWING &&
      this.permissionService.havePermission(FUNCTION_CODE.SO_TYPE23_COL_COMPLETE)
    )
  }

  get cancelPermission(){
    return (
      this.selectedType == SELECTEDTYPE.ADJUSTMENT &&
      this.orderStatusId == STATUSID.ADJ_DRAFT &&
      this.permissionService.havePermission(FUNCTION_CODE.SO_TYPE1_CREATE)
    ) || (
      this.selectedType == SELECTEDTYPE.ADJUSTMENTHKTHOME &&
      this.orderStatusId == STATUSID.ADJ_DRAFT &&
      this.permissionService.havePermission(FUNCTION_CODE.SO_TYPE27_CREATE)
    ) || (
      this.selectedType == SELECTEDTYPE.COLORADJUSTMENT &&
      this.orderStatusId == STATUSID.ADJ_DRAFT &&
      this.permissionService.havePermission(FUNCTION_CODE.SO_TYPE23_COL_CREATE)
    )
  }

  get showNetAmtPermission(){
    return (
      this.selectedType == SELECTEDTYPE.ADJUSTMENT &&
      this.permissionService.havePermission(FUNCTION_CODE.SO_TYPE1_VERIFY)
    ) || (
      this.selectedType == SELECTEDTYPE.ADJUSTMENTHKTHOME &&
      this.permissionService.havePermission(FUNCTION_CODE.SO_TYPE27_VERIFY)
    )
  }

  // count for btn dont enable before other api (LIS net amt) complete
  _updateToNextStatusDisabledCount = 0;
  getUpdateToNextStatusDisabled(){
    return this._updateToNextStatusDisabledCount > 0 || !this.getUpdateToNextStatusPermission()
  }
  setUpdateToNextStatusDisabled(e, force=false){
    if(force){this._updateToNextStatusDisabledCount=0;return;}
    if(e){this._updateToNextStatusDisabledCount++}else{this._updateToNextStatusDisabledCount--}
  }
  getUpdateToNextStatusPermission(){
    return (
      // wip: other type
      !(this.selectedType == this.SELECTEDTYPE.COLORADJUSTMENT
        || this.selectedType == this.SELECTEDTYPE.ADJUSTMENT
        || this.selectedType == this.SELECTEDTYPE.ADJUSTMENTHKTHOME
        || this.selectedType == this.SELECTEDTYPE.ASSORTMENT
        || this.selectedType == this.SELECTEDTYPE.RETURN
        || this.selectedType == this.SELECTEDTYPE.RETURNWOCOURIER
      ) ||
      // channel to warehouse
      (this.selectedType == this.SELECTEDTYPE.RETURN && this.orderStatusId == STATUSID.ALLOCATED &&
        this.permissionService.havePermission(FUNCTION_CODE.SO_TYPE2_TRANSFER)) ||
      (this.selectedType == this.SELECTEDTYPE.RETURN && this.orderStatusId != STATUSID.ALLOCATED) ||
      (this.selectedType == this.SELECTEDTYPE.RETURNWOCOURIER && this.orderStatusId == STATUSID.DRAFT) ||
      (this.selectedType == this.SELECTEDTYPE.RETURNWOCOURIER && this.orderStatusId == STATUSID.ALLOCATED) ||
      // warehouse to channel
      // wip: only set TRANSFERRED need RECEIVE permission for demo
      (this.selectedType == this.SELECTEDTYPE.ASSORTMENT && this.orderStatusId == STATUSID.TRANSFERRED &&
        this.permissionService.havePermission(FUNCTION_CODE.SO_TYPE4_RECEIVE)) ||
      (this.selectedType == this.SELECTEDTYPE.ASSORTMENT && this.orderStatusId == STATUSID.PARTIAL &&
        this.permissionService.havePermission(FUNCTION_CODE.SO_TYPE4_RECEIVE)) ||
      (this.selectedType == this.SELECTEDTYPE.ASSORTMENT && ![STATUSID.TRANSFERRED, STATUSID.PARTIAL].includes(this.orderStatusId)) ||
      // color adj
      (this.selectedType == this.SELECTEDTYPE.COLORADJUSTMENT && this.orderStatusId == STATUSID.ADJ_DRAFT &&
        this.permissionService.havePermission(FUNCTION_CODE.SO_TYPE23_COL_CREATE)) ||
      (this.selectedType == this.SELECTEDTYPE.COLORADJUSTMENT && this.orderStatusId == STATUSID.ADJ_REJECTED &&
        this.permissionService.havePermission(FUNCTION_CODE.SO_TYPE23_COL_CREATE)) ||
      (this.selectedType == this.SELECTEDTYPE.COLORADJUSTMENT && this.orderStatusId == STATUSID.ADJ_REVIEWING &&
        this.permissionService.havePermission(FUNCTION_CODE.SO_TYPE23_COL_COMPLETE)) ||
      // adj
      (this.selectedType == this.SELECTEDTYPE.ADJUSTMENT && this.orderStatusId == STATUSID.ADJ_DRAFT &&
        this.permissionService.havePermission(FUNCTION_CODE.SO_TYPE1_CREATE)) ||
      (this.selectedType == this.SELECTEDTYPE.ADJUSTMENT && this.orderStatusId == STATUSID.ADJ_REVIEWING &&
        this.permissionService.havePermission(FUNCTION_CODE.SO_TYPE1_REVIEW)) ||
      (this.selectedType == this.SELECTEDTYPE.ADJUSTMENT && this.orderStatusId == STATUSID.ADJ_SUBMITTED &&
        this.permissionService.havePermission(FUNCTION_CODE.SO_TYPE1_VERIFY)) ||
      (this.selectedType == this.SELECTEDTYPE.ADJUSTMENT && this.orderStatusId == STATUSID.ADJ_APPROVED &&
        this.permissionService.havePermission(FUNCTION_CODE.SO_TYPE1_FINALREVIEW)) ||
      (this.selectedType == this.SELECTEDTYPE.ADJUSTMENT && this.orderStatusId == STATUSID.ADJ_ADJUSTIP1 &&
        this.permissionService.havePermission(FUNCTION_CODE.SO_TYPE1_FINALREVIEW)) ||
      (this.selectedType == this.SELECTEDTYPE.ADJUSTMENT && this.orderStatusId == STATUSID.ADJ_ADJUSTIP2 &&
        this.permissionService.havePermission(FUNCTION_CODE.SO_TYPE1_COMPLETE)) ||
      (this.selectedType == this.SELECTEDTYPE.ADJUSTMENT && this.orderStatusId == STATUSID.ADJ_REJECTED &&
        this.permissionService.havePermission(FUNCTION_CODE.SO_TYPE1_CREATE)) ||
      // adj for hkt
      (this.selectedType == this.SELECTEDTYPE.ADJUSTMENTHKTHOME && this.orderStatusId == STATUSID.ADJ_DRAFT &&
        this.permissionService.havePermission(FUNCTION_CODE.SO_TYPE27_CREATE)) ||
      (this.selectedType == this.SELECTEDTYPE.ADJUSTMENTHKTHOME && this.orderStatusId == STATUSID.ADJ_REVIEWING &&
        this.permissionService.havePermission(FUNCTION_CODE.SO_TYPE27_REVIEW)) ||
      (this.selectedType == this.SELECTEDTYPE.ADJUSTMENTHKTHOME && this.orderStatusId == STATUSID.ADJ_SUBMITTED &&
        this.permissionService.havePermission(FUNCTION_CODE.SO_TYPE27_VERIFY)) ||
      (this.selectedType == this.SELECTEDTYPE.ADJUSTMENTHKTHOME && this.orderStatusId == STATUSID.ADJ_APPROVED &&
        this.permissionService.havePermission(FUNCTION_CODE.SO_TYPE27_FINALREVIEW)) ||
      (this.selectedType == this.SELECTEDTYPE.ADJUSTMENTHKTHOME && this.orderStatusId == STATUSID.ADJ_ADJUSTIP1 &&
        this.permissionService.havePermission(FUNCTION_CODE.SO_TYPE27_FINALREVIEW)) ||
      (this.selectedType == this.SELECTEDTYPE.ADJUSTMENTHKTHOME && this.orderStatusId == STATUSID.ADJ_ADJUSTIP2 &&
        this.permissionService.havePermission(FUNCTION_CODE.SO_TYPE27_COMPLETE)) ||
      (this.selectedType == this.SELECTEDTYPE.ADJUSTMENTHKTHOME && this.orderStatusId == STATUSID.ADJ_REJECTED &&
        this.permissionService.havePermission(FUNCTION_CODE.SO_TYPE27_CREATE))
    )
  }


  _selectedColumns: any[];
  originColList = [];

  isQtyPositive = false;
  selectedType = "";
  _orderTypeItemData: any = null;
  _adjustmentItemList:any={}
  fromChannelShowValue
  toChannelShowValue
  warehouseList

  uploadModalConfig:any = {
    visible: false,
    title: "Attachment",
    viewOnly: true,
    table: {
      data:[],
      head:[
        {key:'fileName',title:'File Name', width: '8rem',type:"filename" },
        {key:'createDate',title:'Upload Date', width: '8rem',type:'datetime' },
        {key:'delete',title:'Delete', width: '8rem',type:'delete',disabled:true },
      ],
      loading: false,
    }
  }
  popupPUploadEl

  @ViewChild('scanner', { static: false }) scanner: ZXingScannerComponent;

  // received / cancelled / write-off attribute key for serial popup
  serialReceiveStatus = [
    {key: 'received', label: 'Received'},
    {key: 'cancelled', label: 'Cancelled'},
    {key: 'writeOff', label: 'Write Off'},
  ]
  // for serial received / cancelled / write-off table header checkbox to select all
  serialReceiveAll = {
    received:false,
    cancelled:false,
    writeOff:false,
  }

  reasonEditable = false;
  finalReasonEditable = false;
  lineRemarkEditable = false;
  remarkEditable = false;
  qtyAllowNegative = false;
  _appointmentDateInputEditable = false;
  appointmentDate;
  userHaveFromChannelAccess = true;

  get appointmentDateInputEditable(){
    // return true
    return this.selectedType == this.SELECTEDTYPE.RETURNWOCOURIER &&
    [STATUSID.DRAFT].indexOf(this.orderStatusId)>-1&&
    !this.isNew
  }

  get isSerialShowReceive(){
    return (this.selectedType == SELECTEDTYPE.CHANNELTRANSFER ||
      this.selectedType == SELECTEDTYPE.CHANNELTRANSFERCOURIER ||
      this.selectedType == SELECTEDTYPE.ASSORTMENT) &&
      this.orderStatusId != STATUSID.DRAFT
  }

  formats = CONFIG.SCANNERFORMAT;
  SCANNERTRYHARDER = CONFIG.SCANNERTRYHARDER

  title = '';

  constructor(public n: MessageService, public r: Router, public p: ActivatedRoute, public m: ConfirmationService,
    public stockCommonService: StockCommonService,
    public commonService: CommonService,
    public commonStore: CommonStore,
    public stockOrderService: StockOrderService,
    public translate: TranslateService,
    private permissionService: PermissionService,
    public cdRef: ChangeDetectorRef,
    public datepipe: DatePipe,
  ) {
    this._selectedColumns = this.cols;
    this.originColList = this.cols;
    this.getPermission(p)
    this.resetUploadModalConfig()
  }
  initQueryData() {
    this.queryPannel = {
      ipts: [
        {
          title: TITLE.TRANSACTION_TYPE, // for display, can be chinese or english
          name: "orderType", // for database colume
          value: this.orderTypeItem,
          type: INPUT_TYPE.SELECT,
          options: this.orderTypes,
          linkage: "All",
          colspan: 7,
          class: "p-col-12 p-md-4 p-lg-3",
        },
        {
          title: TITLE.ORDER_STATUS,
          name: "orderStatusItem",
          value: this.orderStatusId,
          type: INPUT_TYPE.SELECT,
          options: this.orderStatus,
          disabled: true,
          class: "p-col-12 p-md-4 p-lg-3",
          _exportField: 'statusDescription'
        },
        {
          title: TITLE.ORDER_NUMBER,
          name: "orderNumber",
          value: this.orderNumber,
          disabled: true,
          class: "p-col-12 p-md-4 p-lg-3",
          type: INPUT_TYPE.INPUT,
        },
        {
          title: TITLE.sourceTxnRefHeaderNo,
          name: 'sourceTxnRefHeaderNo',
          value: this.sourceTxnRefHeaderNo,
          class: "p-col-12 p-md-4 p-lg-3",
          disabled: this.isEditable,
          type: INPUT_TYPE.INPUT
        },
        {
          title: TITLE.FROM,
          name: "from",
          value: this.fromChannel,
          showValue: this.fromChannelShowValue,
          type: INPUT_TYPE.TREESELECT,
          options: this.fromOptions,
          disabled: this.isEditable,
          class: "p-col-12 p-md-4 p-lg-3",
          linkage: "FromStatusNature",
        },
        {
          title: TITLE.FROM_STATUS_CONDITION,
          name: "fromStatusNature",
          value: this.statusItem1,
          type: INPUT_TYPE.SELECT,
          options: this.fromStatus,
          disabled: this.isEditable,
          class: "p-col-12 p-md-4 p-lg-3",
        },
        {
          title:TITLE.TO,
          name: "to",
          value: this.toWareHouse,
          showValue: this.toChannelShowValue,
          type: INPUT_TYPE.TREESELECT,
          options: this.toOptions,
          disabled: this.isEditable || this.selectedType == SELECTEDTYPE.CHANGECONDITION,
          class: "p-col-12 p-md-4 p-lg-3",
          linkage: "ToStatusNature",
        },
        {
          title: TITLE.TO_STATUS_CONDITION,
          name: "toStatusNature",
          value: this.statusItem2,
          type: INPUT_TYPE.SELECT,
          options: this.toStatus,
          disabled: this.isEditable,
          class: "p-col-12 p-md-4 p-lg-3",
        },
        // {
        //   title: TITLE.REFERENCE_NUMBER,
        //   name: "deliveryNote",
        //   value: this.deliveryNote,
        //   disabled: this.isNew,
        //   class: "p-col-12 p-md-4 p-lg-3",
        //   type: INPUT_TYPE.INPUT,
        // },
        /* {
          title: TITLE.REASON,
          name: "reason",
          value: null,
          disabled: this.isNew,
          class: "p-col-12 p-md-4 p-lg-3",
          type: INPUT_TYPE.SELECT,
          options: [],
        }, */
        {
          title: TITLE.netAmount,
          name: 'netAmount',
          value: this.netAmount,
          class: "p-col-12 p-md-4 p-lg-3",
          disabled: true,
          type: INPUT_TYPE.INPUT
        },
        {
          title: TITLE.APPOINTMENTDATE,
          name: "appointmentDate",
          class: "p-col-12 p-md-4 p-lg-3",
          type: INPUT_TYPE.DATETIME,
          // showTime: false,
          // stepMinute: 30,
          // hideSeconds: true,
          value: this.getNextDay(),
          disabled: !this.appointmentDateInputEditable,
          // hideOnDateTimeSelect: true,
          defaultDate: this.today,
          minDate: this.today,
        },
        {
          title: TITLE.UPDATEDATE,
          name: "updateDate",
          value: this.updateDate,
          defaultValue: "!!",
          disabled: true,
          class: "p-col-12 p-md-4 p-lg-3",
          type: INPUT_TYPE.DATETIME,
        },
        {
          title: TITLE.UPDATEBY,
          name: 'updateBy',
          value: this.updateBy,
          class: "p-col-12 p-md-4 p-lg-3",
          disabled: true,
          type: INPUT_TYPE.INPUT
        },
        {
          title: TITLE.CREATION_DATE,
          name: "creationDate",
          value: this.creationDate,
          defaultValue: "!!",
          disabled: true,
          class: "p-col-12 p-md-4 p-lg-3",
          type: INPUT_TYPE.DATETIME,
        },
        {
          title: TITLE.CRREATEBY,
          name: 'createBy',
          value: this.createBy,
          class: "p-col-12 p-md-4 p-lg-3",
          disabled: true,
          type: INPUT_TYPE.INPUT
        },
        {
          title: TITLE.REMARK,
          name: "remark",
          value: this.remark,
          type: INPUT_TYPE.TEXTAREA,
          disabled: this.isEditable,
          class: "p-col-12 p-md-4 p-lg-9 textarea-min-width-100",
        },
        {
          title: TITLE.NO_OF_CARTON_BOX,
          name: "noCartonBox",
          class: "p-col-12 p-md-4 p-lg-3",
          type: INPUT_TYPE.INPUTNUMBER,
          value: this.noCartonBox,
          disabled: this.isEditable,
        },
        {
          title: TITLE.internalRemarks,
          name: 'internalRemarks',
          value: this.internalRemarks,
          class: "p-col-12 p-md-4 p-lg-3",
          disabled: this.isEditable,
          type: INPUT_TYPE.INPUT
        },
        {
          title: TITLE.internalReference,
          name: 'internalReference',
          value: this.internalReference,
          class: "p-col-12 p-md-4 p-lg-3",
          disabled: this.isEditable,
          type: INPUT_TYPE.INPUT
        },
      ],
      btnsclass: "p-d-flex p-col-12 p-md-12 p-lg-12 filterBtn p-text-right",
      btns: [
        {
          title: BTN_LABEL.BACK,
          class: "p-button-outlined p-mr-1",
          show: true,
          tag: PANELBTNTAG.BACKTOHISTORYPAGE,
          handler: { click: () => { this.back() } },
        },
        {
          title: BTN_LABEL.CLEAR,
          show: true,
          class: "p-button-outlined p-mr-1",
          tag: PANELBTNTAG.CLEARALL,
          handler: { click: () => { this.refresh() }, },
        },
        {
          title: BTN_LABEL.RETURN,
          class: "p-button-outlined p-mr-1",
          show: true,
          allow: false,
          // permissionType: 'c',
          tag: PANELBTNTAG.RETURN,
          handler: { click: () => { this.return() } },
        },
        {
          title: BTN_LABEL.ADJ_CANCEL,
          class: "p-button-outlined p-mr-1",
          show: true,
          allow: false,
          // permissionType: 'c',
          tag: PANELBTNTAG.ADJ_CANCEL,
          handler: { click: () => { this.cancelOnClick() } },
        },
        {
          title: BTN_LABEL.ADJ_REJECT,
          class: "p-button-outlined p-mr-1",
          show: true,
          allow: false,
          // permissionType: 'c',
          tag: PANELBTNTAG.ADJ_REJECT,
          handler: { click: () => { this.rejectOnClick() } },
        },
        {
          title: BTN_LABEL.EDIT,
          class: "p-mr-1",
          show: false,
          allow: false,
          disabled:!this.isActionEnable,
          // permissionType: 'c',
          tag: PANELBTNTAG.EDIT,
          handler: { click: () => { this.routeToEdit() } },
        },
        {
          title: BTN_LABEL.Attachment,
          class: "p-mr-1",
          show: true,
          allow: false,
          // permissionType: 'c',
          tag: PANELBTNTAG.Attachment,
          handler: { click: () => { this.showUploadModal() } },
        },
        {
          title: BTN_LABEL.EXPORT,
          class: "p-mr-1",
          show: false,
          allow: false,
          // permissionType: 'c',
          tag: PANELBTNTAG.EXPORT,
          handler: { click: () => { this.export() } },
        },
        {
          category: "primary",
          title: BTN_LABEL.EXPORT_DN,
          class: "p-mr-1",
          // permissionType: 'c',
          show: false,
          allow: false,
          tag: PANELBTNTAG.EXPORTDN,
          handler: { click: () => this.exportDN() },
        },
        {
          category: "primary",
          title: BTN_LABEL.SAVE,
          class: "p-mr-1",
          // permissionType: 'c',
          show: true,
          allow: false,
          tag: PANELBTNTAG.SAVEASDRAFT,
          handler: { click: () => { this.submit(this.data) } },
        },
        {
          title: this.btnLabel,
          show: true,
          allow: false,
          class: "",
          disabled: this.getUpdateToNextStatusDisabled(),
          // permissionType: 'c',
          tag: PANELBTNTAG.UPDATETONEXTSTATUS,
          handler: { click: () => { this.confirmUpdate(this.data) } },
        },
      ],
      change: (idx, value, data) => {
        this.queryPanelChange(idx, value, data)
      },
    };
  }

  getNextDay(): Date {
    let today = new Date();
    // 将日期设置为当前日期加上1天，以得到第二天的日期
    today.setDate(today.getDate() + 1);
    this.appointmentDate = today;
    return today;
  }

  orderTypeChanged(idx, value, data){
    this.channels = FilterCommonMethod.getRepoTreeForFilterInput();

    this.orderTypeItem = value;
    // this.isReason = this.orderTypeItem === 1 ? true : false
    const typeObj = data.options.find(item2 => item2.code === value)
    if(typeObj != null) {
      this.selectedType = typeObj.orderTypeCode
    } else {
      this.selectedType = null
    }
    // clear table
    this.clearTable();
    this.dealType(value);
    this.cleanIptsData()
    this.updateToOptionsByType()
    this.initDefaultChannel();//init default channel
    this.handleIptWithSelectedTypeDefault()
    if(
      this.selectedType === SELECTEDTYPE.ADJUSTMENT
      || this.selectedType === SELECTEDTYPE.ADJUSTMENTHKTHOME
      || this.selectedType === SELECTEDTYPE.COLORADJUSTMENT
    ) {
      // load item list when type changed to adjustment since adjustment use all item list
      // adjustment item use all item get from skumodule
      this.loadItemListBySkuModule().subscribe()
    }
    this.setTableColBySelectedType(this.selectedType)
  }

  updateToOptionsByType(){
    let toIptIdx = this.queryPannel.ipts.findIndex(ipt=>ipt.title==TITLE.TO)
    if(this.selectedType === SELECTEDTYPE.RETURN||this.selectedType === SELECTEDTYPE.RETURNWOCOURIER){
      this.toOptions = this.warehouseListForTreeSelectOptions
    }else{
      this.toOptions = this.shopAndWarehouse
    }
    this.queryPannel.ipts[toIptIdx] = {...this.queryPannel.ipts[toIptIdx], options: this.toOptions}
  }

  updateFromOptionsForUamChannel(){
    let fromIptIdx = this.queryPannel.ipts.findIndex(ipt=>ipt.title==TITLE.FROM)
    if(this.isNew){
      this.fromOptions = this.uamChannelOptions
    }else{
      this.fromOptions = this.shopAndWarehouse
    }
    this.queryPannel.ipts[fromIptIdx] = {...this.queryPannel.ipts[fromIptIdx], options: this.fromOptions}
  }

  fromChanged(idx, value, data){
    this.fromChannel = value?.data;
    if(this.selectedType === SELECTEDTYPE.CHANGECONDITION) {
      let toIpt = this.queryPannel.ipts.find(e => e.name === "to");
      if(toIpt) {
        toIpt.value = value;
        toIpt.showValue = value ? value.label : null;
        this.toWareHouse = this.fromChannel;
      }
    }
    // clear table
    this.clearTable();
  }

  queryPanelChange(idx, value, data){
    this[data.name] = data.value;
    if(value&&value.target!=undefined) return
    switch(data.name){
      case 'orderType':
        this.orderTypeChanged(idx, value, data)
        break;
      case 'from':
       this.fromChanged(idx, value, data)
        break;
      case 'to':
        this.toWareHouse = value?.data;
        break;
      case 'fromStatusNature':
        this.statusItem1 = value;
        break;
      case 'toStatusNature':
        this.statusItem2 = value;
        break;
      case 'appointmentDate':
        this.appointmentDate = value
        break;
      case 'noCartonBox':
        this.noCartonBox = this.noCartonBox
        break;
    }
    this.dealType(value);
  }

  initDefaultChannel(){
    let channel = CommonMethod.getDefaultChannel(this.channels)
    if(channel)this.selectedChannel = [channel];
    this.selectedChannelDisplay = FilterCommonMethod.getRepoMultiSelectShowValue(this.selectedChannel);
  }

  ngOnDestroy(): void {
    LocalStorageHelper.remove("line")
    if(this.allocateCreateTimer) clearTimeout(this.allocateCreateTimer)
  }
  @action getPermission(p){
    this.permission = LocalStorageHelper.getObject('PERMISSIONS')[p.snapshot.data.code]
  }

  @computed get showCreate() {
    const typeIpt = this.getQueryIptByLabel(TITLE.TRANSACTION_TYPE)
    return this.permission.some((el:any) => el.type == typeIpt.value && el.desc.includes('Create'))
  }
  @computed get showSearch() {
    let haveSearchPermission = this.permissionService.havePermission(SEARCH_PERMISSION_CODE[this.orderTypeItem])
    return haveSearchPermission
  }

  loadOrderStatus(key = 'ORDER_STATUS'){
    this.orderStatus = LocalStorageHelper.getObject(key).map(
      (item) => {
        let { value: code, label: name, ...other } = item
        return { code, name, ...other }
      }
    )
  }

  getFullOrderStatusList(){
    return [...LocalStorageHelper.getObject('ORDER_STATUS'), ...LocalStorageHelper.getObject('ORDER_ADJUSTMENT_STATUS')].map(
      (item) => {
        let { value: code, label: name, ...other } = item
        return { code, name, ...other }
      }
    )
  }

  loadOrderStatusList(){
    this.orderStatus = this.getFullOrderStatusList()
    // switch(this.selectedType){
    //   case SELECTEDTYPE.ADJUSTMENT:
    //   case SELECTEDTYPE.ADJUSTMENTHKTHOME:
    //   case SELECTEDTYPE.COLORADJUSTMENT:
    //     this.loadOrderStatus('ORDER_ADJUSTMENT_STATUS')
    //     break;
    //   default:
    //     this.loadOrderStatus()
    //     break;
    // }
  }

  ngOnInit(): void {
    // this.orderTypes = LocalStorageHelper.getObject("ORDER_TYPE").filter((item)=>item.isManualEntryAllowed=='Y').map((item) => {
    //   let { value: code, label: name, ...other } = item
    //   return { code, name, ...other }
    // })
    this.translate.get('menu.stock.transaction').subscribe(res=>{
      this.title=res
    })
    this.loadOrderTypeOptions(true)
    this.channels = LocalStorageHelper.getObject("SHOP")
    this.channels2 = LocalStorageHelper.getObject("WAREHOUSE")
    this.warehouseList = LocalStorageHelper.getObject("WAREHOUSE")
    this.sechannels = LocalStorageHelper.getObject("SHOP")
    this.sechannels2 = LocalStorageHelper.getObject("SHOP")
    this.adjReasons = LocalStorageHelper.getObject("REASONS")
    this.colorAdjReasons = LocalStorageHelper.getObject("ADJ_REASONS")

    this.seItems = LocalStorageHelper.getObject("ITEM")
    this.conditions = LocalStorageHelper.getObject("CONDITIONS")

    this.conditions.forEach(condition => {
      this.conditionMapping[condition.code] = condition
    })

    this.loadOrderStatusList()
    this.orderStatusObject = this.orderStatus.find(item=>item.alias === 'Draft')
    this.orderStatusId = this.orderStatusObject.code
    this.status = JSON.parse(localStorage.getItem("STOCK_TYPE"))
    this.shopAndWarehouse = JSON.parse(localStorage.getItem("REPOTREE")).filter(e=>e.children.length>0)
    this.uamChannelOptions = JSON.parse(localStorage.getItem("REPOMODULEBYUAMTREE")).filter(e=>e.children.length>0)
    this.warehouseListForTreeSelectOptions = JSON.parse(localStorage.getItem("REPOTREE")).filter(repo=>repo.key=='Warehouse').filter(e=>e.children.length>0)
    this.toOptions = this.shopAndWarehouse
    this.creationDate = new Date()
    this.updateDate = new Date()


    this.p.queryParams.subscribe(params => {
      if( !params['headId'] ) {
        // gentrannum if no current order number
        this.saveFlag = true // set to true when no ordernumber in params to show button
        this.proceedFlag = true
      }else{
        this.isNew = false
      }
      this.initQueryData()
      this.updateFromOptionsForUamChannel()
      this.setDisabled()
      if(params['headId']){
        this.headId = params['headId']
        this.loadOrderTypeOptions()
        this.updateFromOptionsForUamChannel();
        this.loadDataByHeadId(params['headId'])
      }

    });
  }

  loadDataByHeadId(headId){
    this.stockCommonService.getOrderByHeadId(headId).subscribe(res=>{
      if(res){
        this.loadData(res)
        this.initQueryData()
        let uamChannelList = LocalStorageHelper.getObject('REPOMODULEBYUAM');
        this.userHaveFromChannelAccess = uamChannelList.some(e=>e.id==this.fromChannel);
        this.setDisabled()
        this.afterLoadData()
      }
    },err=>{
      this.showMessage('error', 'System', 'An Unexpected Error Occurred')
    })
  }
  afterLoadData() {
    if(this.orderStatusId == STATUSID.ADJ_PENDING_START_K2){
      let status = this.orderStatus.find(_status=>_status.code == STATUSID.ADJ_PENDING_START_K2)
      this.m.confirm({
        message: 'Please contact IT system administration',
        header: `Warning: Stock Order Status - ${status.name}`,
        acceptVisible: false,
        rejectVisible: true,
        rejectLabel: 'Close'
      });
    }
  }

  // setPermission(list) {
  //   list.forEach(item => {
  //     /* item.title === BTN_LABEL.EXPORT_DN && (item.disabled = !this.permission.includes('Export DN'))
  //     item.title === BTN_LABEL.ADJUST && (item.disabled = !this.permission.includes('Adjust'))
  //     item.title === BTN_LABEL.ALLOCATE && (item.disabled = !this.permission.includes('Allocate'))
  //     item.title === BTN_LABEL.STOCK_IN && (item.disabled = !this.permission.includes('Stock In'))
  //     item.title === BTN_LABEL.TRANSFER && (item.disabled = !this.permission.includes('Transfer'))
  //     item.title === BTN_LABEL.RECEIVE_FOR_INSPECTION && (item.disabled = !this.permission.includes('Receive for Inspection'))
  //     item.title === BTN_LABEL.COMPLETE && (item.disabled = !this.permission.includes('Complete'))
  //     item.title === BTN_LABEL.CONFIRM && (item.disabled = !this.permission.includes('Confirm'))
  //     item.title === BTN_LABEL.SAVE && (item.disabled = !this.permission.includes('Save'))
  //     item.title === BTN_LABEL.EDIT && (item.disabled = !this.permission.includes('Edit'))
  //     item.title === BTN_LABEL.CLEAR && (item.disabled = !this.permission.includes('Clear')) */
  //     // item.show = true
  //     /* if(item.permissionType){
  //       let permission = this.defaultPermission[item.permissionType]
  //       const flag =  this.permission.includes('')
  //       item.show = flag
  //     } */
  //   })
  // }

  // loadLineDataByHeadId(headId) {
  //   this.stockCommonService.getOrderByHeadId(headId).subscribe(res=>{
  //     if(res){
  //       this.lineData = res.lines;
  //       this.lineData.forEach(line => {
  //         line.quantity = line.qty
  //         line.itemCode = line.item
  //         line.stockOrderLineId = line.lineId
  //       })
  //       this.initLineDataSerialControl()
  //       this.initLineDataForPartialReceive()
  //     }
  //   })
  // }

  initLineDataForPartialReceive(): void{
    if(
      ![SELECTEDTYPE.ASSORTMENT, SELECTEDTYPE.NOPREPLENISHMENT, SELECTEDTYPE.CHANNELTRANSFER, SELECTEDTYPE.CHANNELTRANSFERCOURIER].includes(this.selectedType)
    ) return
    this.lineData.forEach(line=>{
      line._buffer = {
        originQtyActual:line.qtyActual,
        originQtyCancelled:line.qtyCancelled,
        originQtyWriteOff:line.qtyWriteOff,
      }
      if(this.orderStatusId==STATUSID.RECEIVED){
        // received and ready to complete
        line.qtyActual = line.preQtyActual;
        line.qtyCancelled = line.preQtyCancelled;
        line.qtyWriteOff = line.preQtyWriteOff;
      }else{
        line.qtyActual=line.qtyActual?line.qtyActual:0;
        line.qtyCancelled=line.qtyCancelled?line.qtyCancelled:0;
        line.qtyWriteOff=line.qtyWriteOff?line.qtyWriteOff:0;
      }
      line.serials = this.mapSerialForParticalReceived(line.serials)
    })
  }

  mapSerialForParticalReceived(serials){
    return serials.map(serial=>{
      if(this.orderStatusId==STATUSID.RECEIVED){
        serial.received = serial.preReceived == 'Y';
        serial.cancelled = serial.preCancelled == 'Y';
        serial.writeOff = serial.preWriteOff == 'Y';
      }else{
        serial.received = serial.isReceived == 'Y';
        serial.cancelled = serial.isCancelled == 'Y';
        serial.writeOff = serial.isWriteOff == 'Y';
      }
      return {
        ...serial,
        skipCheckForReceive: (serial.isReceived=='Y'||serial.isCancelled=='Y'||serial.isWriteOff=='Y')
      }
    })
  }

  initLineDataSerialControl(){
    this.lineData.forEach(line => {
      this.items.forEach(ele => {
        if (ele.code == line.itemId) {
          // console.log(ele)
          line.isserialcontrol = ele._data.isserialcontrol
        }
      })
    })
  }

  getType(v) {
    // console.log(typeof v);
    return typeof v
  }
  exportDN() {
    if(this.loadingSwitch) return
    this.loadingSwitch++
    this.stockCommonService.getDmDeliveryDoc({orderId:this.headId}).subscribe(res=>{
      this.loadingSwitch--
      if(res.code=='000'){
        let blob = CommonMethod.base64toBlob(res.data[0].fileBase64)
        CommonMethod.saveBlobData(blob,`DM_Delivery_Note_${this.sourceTxnRefHeaderNo}.pdf`)
        // window.open(url);
      }else{
        this.showMessage('error', NOTI_TITLE, res.message || res.msg)
      }
    },err=>{
      this.loadingSwitch--
      this.showMessage('error', NOTI_TITLE, 'An Unexpected Error Occurred')
    },()=>{
    })
  }
  showExportDN() {
    // const typeIpt = this.getQueryIptByLabel(TITLE.TRANSACTION_TYPE)
    // const statusIpt = this.getQueryIptByLabel(TITLE.ORDER_STATUS)
    // if (type='Channel to Channel By Saff' typeIpt.value=5) and (state='Confirmed' statusIpt.value=8) return true
    return (
      // Channel to Channel by Staff
      this.selectedType == SELECTEDTYPE.CHANNELTRANSFER && (
        this.orderStatusId == STATUSID.ALLOCATED ||
        this.orderStatusId == STATUSID.TRANSFERRED ||
        this.orderStatusId == STATUSID.RECEIVED ||
        this.orderStatusId == STATUSID.COMPLETED ||
        this.orderStatusId == STATUSID.PARTIAL
      ) ||
      // Channel to Warehouse
      this.selectedType == SELECTEDTYPE.RETURN && (
        this.orderStatusId == STATUSID.ALLOCATED ||
        this.orderStatusId == STATUSID.TRANSFERRED ||
        this.orderStatusId == STATUSID.RECEIVED ||
        this.orderStatusId == STATUSID.COMPLETED ||
        this.orderStatusId == STATUSID.PARTIAL
      ) ||
      // Channel to Warehouse
      this.selectedType == SELECTEDTYPE.RETURNWOCOURIER && (
        this.orderStatusId == STATUSID.ALLOCATED ||
        this.orderStatusId == STATUSID.TRANSFERRED ||
        this.orderStatusId == STATUSID.RECEIVED ||
        this.orderStatusId == STATUSID.COMPLETED ||
        this.orderStatusId == STATUSID.PARTIAL
      ) ||
      // Channel to Channel By Courier
      this.selectedType == SELECTEDTYPE.CHANNELTRANSFERCOURIER && (
        this.orderStatusId == STATUSID.ALLOCATED ||
        this.orderStatusId == STATUSID.TRANSFERRED ||
        this.orderStatusId == STATUSID.RECEIVED ||
        this.orderStatusId == STATUSID.COMPLETED ||
        this.orderStatusId == STATUSID.PARTIAL
      ) ||
      // Warehouse to Channel
      this.selectedType == SELECTEDTYPE.ASSORTMENT && (
        this.orderStatusId == STATUSID.ALLOCATED ||
        this.orderStatusId == STATUSID.TRANSFERRED ||
        this.orderStatusId == STATUSID.RECEIVED ||
        this.orderStatusId == STATUSID.COMPLETED ||
        this.orderStatusId == STATUSID.PARTIAL
      )
      //  ||
      // // Warehouse to Customer
      // this.selectedType == SELECTEDTYPE.WAREHOUSEHOMED && (
      //   this.orderStatusId == STATUSID.ALLOCATED ||
      //   this.orderStatusId == STATUSID.TRANSFERRED ||
      //   this.orderStatusId == STATUSID.RECEIVED ||
      //   this.orderStatusId == STATUSID.COMPLETED ||
      //   this.orderStatusId == STATUSID.PARTIAL
      // ) ||
      // // Channel to Customer
      // this.selectedType == SELECTEDTYPE.CHANNELHOMED && (
      //   this.orderStatusId == STATUSID.ALLOCATED ||
      //   this.orderStatusId == STATUSID.TRANSFERRED ||
      //   this.orderStatusId == STATUSID.RECEIVED ||
      //   this.orderStatusId == STATUSID.COMPLETED ||
      //   this.orderStatusId == STATUSID.PARTIAL
      // )
    ) ? true : false
  }
  setDisabled() {
    this.queryPannel.ipts.forEach((item) => {
      let disable = this.disabledList.includes(item.title)

      if (disable || item.title === TITLE.NO_OF_CARTON_BOX || item.title == TITLE.netAmount) {
        item.disabled = true
      } else if (item.title === TITLE.TRANSACTION_TYPE) {
        item.disabled = !this.isNew;
		  } else {
        item.disabled = !this.isEditable || !this.orderTypeItem;
      }

      switch(item.title){
        case TITLE.sourceTxnRefHeaderNo:
          item.show = true
          break;
        case TITLE.internalRemarks:
          item.show = this.selectedType == SELECTEDTYPE.ADJUSTMENT || this.selectedType == SELECTEDTYPE.ADJUSTMENTHKTHOME
          break;
        case TITLE.REMARK:
          item.show = true;
          break;
        case TITLE.internalReference:
          item.show = (
            this.selectedType == SELECTEDTYPE.ADJUSTMENT
            || this.selectedType == SELECTEDTYPE.ADJUSTMENTHKTHOME
          )
          break;
        case TITLE.netAmount:
          item.show = (this.selectedType == SELECTEDTYPE.ADJUSTMENT || this.selectedType == SELECTEDTYPE.ADJUSTMENTHKTHOME)
            && this.orderStatusId == STATUSID.ADJ_SUBMITTED && this.showNetAmtPermission
          break;
        case TITLE.NO_OF_CARTON_BOX:
          item.show = this.selectedType == SELECTEDTYPE.RETURN || this.selectedType == SELECTEDTYPE.RETURNWOCOURIER
          break
        case TITLE.APPOINTMENTDATE:
          item.show = this.appointmentDateInputEditable
          break
      }
    });
    this.handleIptWithSelectedType()
    this.initReason()
    this.queryPannel.btns.forEach((item) => {
      item.allow = this.showCreate
      switch (item.tag) {
        case PANELBTNTAG.EXPORTDN:
          item.show = this.headId ? this.showExportDN() : false
          break;
        case PANELBTNTAG.EXPORT:
          item.show = !this.isNew
          break;
        case PANELBTNTAG.CLEARALL:
          item.show = this.isEditable && this.showCreate
          break;
        case PANELBTNTAG.SAVEASDRAFT:
          item.show = this.isEditable && this.showCreate
          break;
        case PANELBTNTAG.BACKTOHISTORYPAGE:
          item.show = true
          break;
        case PANELBTNTAG.UPDATETONEXTSTATUS:
          item.show = !this.isNew && this.proceedFlag && this.showSearch
          break;
        case PANELBTNTAG.EDIT:
          if(this.selectedType == SELECTEDTYPE.ADJUSTMENT || this.selectedType == SELECTEDTYPE.ADJUSTMENTHKTHOME || this.selectedType == SELECTEDTYPE.COLORADJUSTMENT){
            item.show = !this.isNew && [
              STATUSID.ADJ_DRAFT,
              STATUSID.ADJ_REVIEWING,
              STATUSID.ADJ_SUBMITTED,
              STATUSID.ADJ_ADJUSTIP1,
            ].indexOf(this.orderStatusId)>-1
            && this.showCreate
            && this.userHaveFromChannelAccess
          }else{
            item.show = !this.isNew && this.isDraft && this.proceedFlag && this.showCreate
          }
          break;
        case PANELBTNTAG.RETURN:
          item.show = this.showReturn;
          break;
        case PANELBTNTAG.ADJ_REJECT:
          item.show = this.showReject;
          item.disabled = !this.rejectPermission;
          break;
        case PANELBTNTAG.ADJ_CANCEL:
          item.show = this.showCancel;
          item.disabled = !this.cancelPermission;
          break;
        case PANELBTNTAG.Attachment:
          item.show = !this.isNew;
          break;
        default:
          item.show = this.proceedFlag && this.showCreate
          break;
      }
    });
    this.reasonEditable = (
      this.orderTypeItem == ORDERTYPEID.ADJUSTMENT && [STATUSID.ADJ_DRAFT,STATUSID.ADJ_REVIEWING].indexOf(this.orderStatusId)>-1
      || this.orderTypeItem == ORDERTYPEID.ADJUSTMENTHKTHOME && [STATUSID.ADJ_DRAFT,STATUSID.ADJ_REVIEWING].indexOf(this.orderStatusId)>-1
      || this.orderTypeItem == ORDERTYPEID.COLORADJUSTMENT && [STATUSID.ADJ_DRAFT].indexOf(this.orderStatusId)>-1
    )
    this.finalReasonEditable = (
      this.orderTypeItem == ORDERTYPEID.ADJUSTMENT && [STATUSID.ADJ_DRAFT,STATUSID.ADJ_REVIEWING,STATUSID.ADJ_SUBMITTED, STATUSID.ADJ_ADJUSTIP1].indexOf(this.orderStatusId)>-1
      || this.orderTypeItem == ORDERTYPEID.ADJUSTMENTHKTHOME && [STATUSID.ADJ_DRAFT,STATUSID.ADJ_REVIEWING,STATUSID.ADJ_SUBMITTED, STATUSID.ADJ_ADJUSTIP1].indexOf(this.orderStatusId)>-1
      // || this.orderTypeItem == ORDERTYPEID.COLORADJUSTMENT && [].indexOf(this.orderStatusId)>-1
    )
    this.lineRemarkEditable = (
      this.orderTypeItem == ORDERTYPEID.ADJUSTMENT && [STATUSID.ADJ_DRAFT,STATUSID.ADJ_REVIEWING].indexOf(this.orderStatusId)>-1
      || this.orderTypeItem == ORDERTYPEID.ADJUSTMENTHKTHOME && [STATUSID.ADJ_DRAFT,STATUSID.ADJ_REVIEWING].indexOf(this.orderStatusId)>-1
      || this.orderTypeItem == ORDERTYPEID.COLORADJUSTMENT && [STATUSID.ADJ_DRAFT,STATUSID.ADJ_REVIEWING].indexOf(this.orderStatusId)>-1
    )
    this.qtyAllowNegative = (
      this.orderTypeItem == ORDERTYPEID.ADJUSTMENT
      || this.orderTypeItem == ORDERTYPEID.ADJUSTMENTHKTHOME
      || this.orderTypeItem == ORDERTYPEID.COLORADJUSTMENT
    )
    this._appointmentDateInputEditable = this.appointmentDateInputEditable
    this.initUpdateBtnDisabled()
  }
  getQueryIptByLabel(name) {
    return this.queryPannel.ipts.find(item => item.title === name)
  }
 /*  setIptDisabled(obj){
    const fromIpt = this.getQueryIptByLabel(TITLE.FROM)
    const fromStatusIpt = this.getQueryIptByLabel(TITLE.FROM_STATUS_CONDITION)
    const toIpt = this.getQueryIptByLabel(TITLE.TO)
    const toStatusIpt = this.getQueryIptByLabel(TITLE.TO_STATUS_CONDITION)

    fromStatusIpt.value = null
    fromIpt.showValue = null
    fromIpt.value = null
    toIpt.showValue = null
    toIpt.value = null
    toStatusIpt.value = null

    if(obj){
      fromStatusIpt.disabled = obj.isOrderChannelConditionRequired === "Y" ? false : true
      toIpt.disabled = obj.isCrossChannelsOnly === "Y" ? false : true
      toStatusIpt.disabled = obj.isConditionChangeAllowed === "Y" ? false : true
    }else{
      fromStatusIpt.disabled = true
      toIpt.disabled = true
      toStatusIpt.disabled = true
    }
  } */
  initDataFromLoadData(da){
    this.isNew = false
    this.saveFlag = false
    this.proceedFlag = false
    this.orderNumber = da.orderNumber
    this.orderTypeItem = this.orderTypes.find(item=>item.id === +da.orderTypeId)?.code //Number(da.orderTypeId)
    this.selectedType = this.orderTypes.find(item=>item.id === +da.orderTypeId)?.orderTypeCode;
    this.loadOrderStatusList()
    this.setTableColBySelectedType(this.selectedType)
    this.orderTypeItemLabel = da.orderType
    // this.isReason = this.orderTypeItem === 1 ? true : false
    this.fromChannel = isNaN(Number.parseInt(da.fromChannelId, 10)) ? null : Number.parseInt(da.fromChannelId, 10);
    let fromChannelObj = this.getConditionFromShopAndWarehouseById(this.fromChannel);
    this.fromChannelShowValue = fromChannelObj ? fromChannelObj.label : null;
    this.toWareHouse = isNaN(Number.parseInt(da.toChannelId, 10)) ? null : Number.parseInt(da.toChannelId, 10);
    let toChannelObj = this.getConditionFromShopAndWarehouseById(this.toWareHouse)
    this.toChannelShowValue = toChannelObj?toChannelObj.label:null
    // this.orderStatusItem = this.orderStatus.find(item=>item.name.toUpperCase() === da.orderStatus)?.code
    this.orderStatusObject = this.orderStatus.find(item=>item.code == da.orderStatus)
    this.orderStatusId = this.orderStatusObject?.code
    this.statusItem1 = da.fromConditionCode;
    this.fromStatus = da.fromConditionCode?[{name:da.fromStatusNature, code:da.fromConditionCode}]:[]
    this.statusItem2 = da.toConditionCode;
    this.toStatus = da.toConditionCode?[{name:da.toStatusNature, code:da.toConditionCode}]:[]
    this.internalRemarks = da.internalRemarks;
    this.internalReference = da.internalReference;
    this.sourceTxnType = da.sourceTxnType;
    this.lineData = da.lines.map(line=>{
      return {
        ...line,
        reason: line.reasonId,
        finalReason: line.finalReasonId,
        stockOrderLineId : line.lineId
      }
    });
    this.handleStatusFromData(da)
    this.creationDate = new Date(+da.creationDate)
    this.updateDate = new Date(+da.updateDate)
    this.deliveryNote = da.dmDnNuber
    this.remark = da.remark
    this.noCartonBox = da.noCartonBox
    this.sourceTxnRefHeaderNo=da.sourceTxnRefHeaderNo
    this.updateBy=da.updateBy
    this.createBy=da.createBy
    this.checkActionDisable(this.fromChannel)
    this.checkPermissionAfterLoadDataFromHeadId()
  }
  checkPermissionAfterLoadDataFromHeadId(){
    if(this.headId && !this.isNew && this.orderTypeItem){
      let haveSearchPermission = this.permissionService.havePermission(SEARCH_PERMISSION_CODE[this.orderTypeItem])
      if(!haveSearchPermission) this.back()
    }
  }
  loadData(da) {
    // console.log('da:', da)
    let that = this;
    this.initDataFromLoadData(da)
    // this.items = LocalStorageHelper.getObject("ITEM") //JSON.parse(localStorage.getItem("SKU"))
    // this.reasons = LocalStorageHelper.getObject("REASONS")

    if(!(this.selectedType == SELECTEDTYPE.ADJUSTMENT ||
        this.selectedType == SELECTEDTYPE.ADJUSTMENTHKTHOME ||
        this.selectedType == SELECTEDTYPE.COLORADJUSTMENT ||
        this.selectedType == SELECTEDTYPE.ASSORTMENT ||
        this.selectedType == SELECTEDTYPE.NOPREPLENISHMENT ||
        this.selectedType == SELECTEDTYPE.CHANNELTRANSFER ||
        this.selectedType == SELECTEDTYPE.CHANNELTRANSFERCOURIER ||
        this.selectedType == SELECTEDTYPE.CHANNELHOMEDRETURN ||
        this.selectedType == SELECTEDTYPE.WAREHOUSEHOMEDRETURN)
      && !this.headId
    ){
      if(da.fromChannelId && da.fromConditionCode) {
        this.loadFromNaturePost(URLDICT.COMMON_SEARCH_ITEMBYCHANNELIDANDCONDITIONID,
          {channelId: da.fromChannelId, conditionCode: da.fromConditionCode}, () => {
          that.initLineDataSerialControl()
        })
      }
    }
    if(
      this.selectedType == SELECTEDTYPE.ASSORTMENT ||
      this.selectedType == SELECTEDTYPE.NOPREPLENISHMENT ||
      this.selectedType == SELECTEDTYPE.CHANNELTRANSFER ||
      this.selectedType == SELECTEDTYPE.CHANNELTRANSFERCOURIER ||
      this.selectedType == SELECTEDTYPE.CHANNELHOMEDRETURN ||
      this.selectedType == SELECTEDTYPE.WAREHOUSEHOMEDRETURN
    ){
      this.initLineDataForPartialReceive()


      this.loadItemListByItemIdList(da.lines.map(line => line.itemId), () => {
        that.initLineDataSerialControl()
      })
    }else if(
      this.headId ||
      this.selectedType == SELECTEDTYPE.ADJUSTMENT ||
      this.selectedType == SELECTEDTYPE.ADJUSTMENTHKTHOME ||
      this.selectedType == SELECTEDTYPE.COLORADJUSTMENT ||
      this.selectedType == SELECTEDTYPE.WAREHOUSEHOMED
    ){
      // stockadjustment load item list per item
      let itemStatusConditionObs = this.stockCommonService.getFromNature(da.orderTypeId, da.fromChannelId).pipe(
        tap(res=>{
          this.fromStatus = []
          if (res) {
            this.fromNatures = res
            res.forEach(element => {
              this.fromStatus.push({code: element.code, description: element.description, value: element.code, name: element.description})
            });
          }
        })
      )
      let lineDataItemListObservables = []

      this.loadingSwitch++;
      this.loadItemListBySkuModule().subscribe(res=>{
        this.loadingSwitch--;
        that.initLineDataSerialControl()
      }, err=>{
        this.loadingSwitch--;
        this.showMessage('error', 'System', 'An Unexpected Error Occurred')
      })

      this.loadingSwitch++;
      forkJoin([itemStatusConditionObs, ...lineDataItemListObservables]).subscribe(
        res=>{this.loadingSwitch--},
        err=>{this.loadingSwitch--;
          this.showMessage('error', 'System', 'An Unexpected Error Occurred')
        }
      )
    }
    // if(this.selectedType == SELECTEDTYPE.ADJUSTMENT && this.orderStatusId == STATUSID.ADJ_SUBMITTED){
    //   this.getNetAmount()
    // }
    this.lineData.forEach(line => {
      line.quantity = line.qty
      line.itemCode = line.item
    })

    Object.assign(this.data, da)
    let _lineData = this.lineData.map((line, idx)=>{
      return {...line, lineNumber: idx+1}
    })
    this.data['line'] = _lineData
  }

  handleStatusFromData(da){
    let typeId = da.orderTypeId?da.orderTypeId:this.orderTypeItem;
    this.isDraft = false;
    switch(this.orderStatusId){
      case STATUSID.DRAFT:{
        this.isDraft = true
        this.proceedFlag = true
        // orderTypeId should be number
        if(typeId == ORDERTYPEID.CHANGECONDITION || typeId == ORDERTYPEID.CHANNELTRANSFER || typeId == ORDERTYPEID.CHANNELTRANSFERCOURIER)
          this.btnLabel = BTN_LABEL.ALLOCATE
        /*else if (typeId == ORDERTYPEID.CHANGECONDITION) {
          // change stock condition directly draft to completed
          this.btnLabel = BTN_LABEL.STOCK_IN
        }*/else if (typeId == ORDERTYPEID.ADJUSTMENT || typeId == ORDERTYPEID.ADJUSTMENTHKTHOME) {
          // change stock condition directly draft to completed
          this.btnLabel = BTN_LABEL.ADJUST
        }else if(typeId == ORDERTYPEID.RETURN || typeId == ORDERTYPEID.RETURNWOCOURIER){
          this.btnLabel = BTN_LABEL.ALLOCATE
        }
        break;
      }
      case STATUSID.ALLOCATED:{
        this.proceedFlag = true
        if([
          ORDERTYPEID.RETURN, // unable to change return order status from confirmed to transfer in ui
        ].includes(typeId)) this.proceedFlag = false;
        if (typeId == ORDERTYPEID.CHANGECONDITION){
          this.btnLabel = BTN_LABEL.STOCK_IN
        }else {
          this.btnLabel = BTN_LABEL.TRANSFER
        }
        break;
      }
      case STATUSID.PARTIAL:
      case STATUSID.TRANSFERRED:
        this.proceedFlag = true
        if(typeId == ORDERTYPEID.WAREHOUSEHOMEDRETURN) this.proceedFlag = false;
        if(typeId == ORDERTYPEID.CHANNELHOMEDRETURN) this.proceedFlag = false;
        if(
          typeId == ORDERTYPEID.RETURN ||
          typeId == ORDERTYPEID.RETURNWOCOURIER ||
          typeId == ORDERTYPEID.WAREHOUSEHOMED ||
          typeId == ORDERTYPEID.CHANNELHOMED
          // || typeId == ORDERTYPEID.WAREHOUSEHOMEDRETURN
        ){
          this.btnLabel = BTN_LABEL.COMPLETE
        }else{
          this.btnLabel = BTN_LABEL.RECEIVE_FOR_INSPECTION
        }
        break;
      case STATUSID.RECEIVED:{
        this.proceedFlag = true
        if(typeId == ORDERTYPEID.CHANNELHOMEDRETURN) this.proceedFlag = false;
        this.btnLabel = BTN_LABEL.STOCK_IN
        // if(
        //   typeId == ORDERTYPEID.CHANNELHOMEDRETURN
        // ){
        //   this.btnLabel = BTN_LABEL.COMPLETE
        // }
        break;
      }
      case STATUSID.WAITING_TO_COMPLETE:
      case STATUSID.COMPLETED:{
        this.saveFlag = false
        this.proceedFlag = false
        break;
      }
      // adjustment status
      case STATUSID.ADJ_DRAFT:
        this.proceedFlag = true
        this.btnLabel = BTN_LABEL.ADJ_REVIEW
        if(typeId == ORDERTYPEID.COLORADJUSTMENT){
          // this.getNetAmount()
        }
        break;
      case STATUSID.ADJ_REVIEWING:
        this.proceedFlag = true
        this.btnLabel = BTN_LABEL.ADJ_SUBMIT
        if(typeId == ORDERTYPEID.COLORADJUSTMENT){
          this.btnLabel = BTN_LABEL.ADJ_ADJUST
          // this.getNetAmount()
        }
        break;
      case STATUSID.ADJ_SUBMITTED:
        this.proceedFlag = true
        this.btnLabel = BTN_LABEL.ADJ_VERIFY
        this.getNetAmountForDisplayAndUpdateBtnDisabled()
        break;
      case STATUSID.ADJ_VERIFIED:
      case STATUSID.ADJ_APPROVEIP:
        this.proceedFlag = false;
        break;
      case STATUSID.ADJ_APPROVED:
        this.proceedFlag = true
        this.btnLabel = BTN_LABEL.ADJ_TOADJUSTIP1
        break;
      case STATUSID.ADJ_REJECTED:
        this.proceedFlag = true
        this.btnLabel = BTN_LABEL.ADJ_REWORK
        break;
      case STATUSID.ADJ_ADJUSTIP1:
        this.proceedFlag = true
        this.btnLabel = BTN_LABEL.ADJ_FIN_REVIEW
        break;
      case STATUSID.ADJ_ADJUSTIP2:
        this.proceedFlag = true
        this.btnLabel = BTN_LABEL.ADJ_ADJUST
        break;
      case STATUSID.ADJ_COMPLETED:
        this.saveFlag = false
        this.proceedFlag = false
        break;
    }
  }

  toChannelLabel(v) {
    return CommonMethod.toSelectedLabel("REPO", v)
  }

  /* initOrderType() {
    HttpHelper.post(URLDICT.STOCK_TRANSFER_ORDERTYPE, {}).then(res => {

      if (res.code == '000' && res.data.length > 0) {
        this.orderTypes = []
        res.data.forEach(element => {
          const data = {value: element.id, label: element.description, description: element.itemdesc, flag: null, itemCode: element.item, other: null, _data: element}
          this.orderTypes.push(data)
        });
      } else {
        // this.n.warning("System", "There is no sku in the current channel!")
        this.orderTypes = []
      }
      console.log(this.orderTypes);
    })
  } */

  /* search() {
    let data = {
      "orderTypeItem": this.orderTypeItem,
      "orderNumber": this.orderNumber,
      "orderStatusItem": this.orderStatusItem,
      "fromChannel": this.fromChannel,
      "toWareHouse": this.toWareHouse,
      "creationDate": this.creationDate,
      "statusItem1": this.statusItem1,
      "statusItem2": this.statusItem2,
      "deliveryNote": this.deliveryNote,
      "pageIndex": this.pageIndex,
      "pageSize": this.pageSize,
    }
    //console.log(data);
    this.loadingSwitch++

  } */

  refresh() {
    const list = ['orderType', 'from', 'to','fromStatusNature', 'toStatusNature', 'remark', 'noCartonBox']
    this.queryPannel.ipts.forEach((item) => {
      if (list.includes(item.name)) {
        if((item.name == 'orderType' && !this.isDraft) || item.name != 'orderType'){
          item.showValue = '';
          item.value = null;
        }
      }
    });
    this.items = null
    this.adjReasons = null
    this.seItem = null
    this.fromChannel = null
    this.toWareHouse = null
    this.orderTypeItem = null
    this.statusItem1 = null
    this.statusItem2 = null
    this.noCartonBox = null
    // this.btnLabel = this.btnLabel
    this.setDisabled();
    this.clearTable();
  }

  dealType(e) {
    // creating order, type value change
    if(
      this.selectedType == SELECTEDTYPE.ADJUSTMENT
      || this.selectedType == SELECTEDTYPE.ADJUSTMENTHKTHOME
      || this.selectedType == SELECTEDTYPE.COLORADJUSTMENT
    ){
      this.orderStatusId = STATUSID.ADJ_DRAFT
    }else{
      this.orderStatusId = STATUSID.DRAFT
    }

    this.setDisabled();
    this.dealItem()
    this.dealToItem()
  }

  //根据channel联动sku数据
  dealItem() {
    if(this.orderTypeItem && this.fromChannel) {
      const obj = { orderTypeId: this.orderTypeItem, fromChannelId: this.fromChannel }
      this.loadFromNaturePre(obj, URLDICT.STOCK_TRANSFER_GETFROMNATURE, this.fromChannel, this.fromNatures)
    }
  }

  dealToItem() {
    if(this.orderTypeItem && this.toWareHouse){
      this.loadToNature({orderTypeId: this.orderTypeItem, toChannelId: this.toWareHouse}, URLDICT.STOCK_TRANSFER_GETTONATURE)
    }
  }

  loadFromNature(pa, url) {
    this.commonService.httpPost(url, pa)
    // HttpHelper.post(url, pa)
    .then(res => {
      this.fromStatus = []
      if (res.code == '000' && res.data.length > 0) {
        res.data.forEach(element => {
          this.fromStatus.push({value: element, label: element})
        });
      }
    })
  }

  loadFromNaturePre(pa, url, channelId, conditionId) {
  // console.log('loadSKU(fromChange)', channelId, this.statusItem1)
  this.commonService.httpPost(url, pa)
    // HttpHelper.post(url, pa)
    .then(res => {
      this.fromStatus = []
      if (res.code == '000' && res.data.length > 0) {
        this.fromNatures = res.data
        let conditionBlackList = (<string[]>CONFIG.ORDER_CONDITION_BLACKLIST).map(e=>e.toLowerCase())
        if (channelId && this.statusItem1)
          this.loadFromNaturePost(URLDICT.COMMON_SEARCH_ITEMBYCHANNELIDANDCONDITIONID, {channelId, conditionCode: this.statusItem1})
        res.data.forEach(element => {
          if(!conditionBlackList.includes(element.description.toLowerCase()))
            this.fromStatus.push({code: element.code, description: element.description, value: element.code, name: element.description})
        });
      }
      const fromStatus = this.queryPannel.ipts.find((item) => item.name === "fromStatusNature");
      fromStatus.options = this.fromStatus;
    })
  }

  loadFromNaturePost(url, pa, cb: Function = () => {}) {
    this.loadingSwitch++
    this.commonService.httpPost(url, pa)
    // HttpHelper.post(url, pa)
    .then(res => {
      if (res.code == '000' && res.data.length > 0) {
        this.items = []
        res.data.forEach(element => {
          this.items.push({code: element.itemid, name: element.codedesc, flag: element.itemdesc, itemCode: element.item, _data: element})
        });
        if(cb){
          cb()
        }
      } else {
        // this.n.warning("System", "There is no sku in the current channel!")
        this.showMessage('warn','System', 'There is no sku in the current channel!')
        this.items = []
      }
    }).catch(err=>{
      this.showMessage('error', 'System', 'An Unexpected Error Occurred')
    }).finally(()=>{
      this.loadingSwitch--
    })
  }

  loadItemListByItemIdList(idList: string[], cb: Function = () => {}) {
    this.loadingSwitch++;
    this.stockCommonService.getItemListByItemId({itemId: idList}).pipe().subscribe(res => {
      this.loadingSwitch--
      if (res) {
        this.items = []
        res.forEach(element => {
          this.items.push({code: element.itemid, name: element.codedesc, flag: element.itemdesc, itemCode: element.item, _data: element})
        });
        if(cb){
          cb()
        }
      }
    }, err=>{
      this.loadingSwitch--
      this.showMessage('error', 'System', 'An Unexpected Error Occurred')
    })
  }

  loadItemListBySkuModule(){
    return this.commonService.getSkuModuleList().pipe(

      switchMap(res=>{
        return this.stockCommonService.getItemListByItemId({itemId: res.map(sku=>sku.value)})
      }),
      tap(res=>{
        if (res) {
          let _items = []
          res.forEach(element => {
            _items.push({code: element.itemid, name: element.codedesc, flag: element.itemdesc, itemCode: element.item, _data: element})
          });
          this.items = _items;
        }
      })
    )
  }

  loadToNature(pa, url) {
    this.toStatus = []
    this.commonService.httpPost(url, pa)
    // HttpHelper.post(url, pa)
    .then(res => {
      if (res.code == '000' && res.data.length > 0) {
        //sku only showed if channelid and conditionid are selected
        let conditionBlackList = (<string[]>CONFIG.ORDER_CONDITION_BLACKLIST).map(e=>e.toLowerCase())
        this.toStatus = []
        res.data.forEach(element => {
          if(!conditionBlackList.includes(element.description.toLowerCase()))
            this.toStatus.push({code: element.code, description: element.description, value: element.code, name: element.description})
        });
      } else {
        this.toStatus = []
      }
      const toStatus = this.queryPannel.ipts.find( (item) => item.name === "toStatusNature" );
      toStatus.options = this.toStatus;
    })
  }

//根据item联动desc
  itemChange(e, data) {
    let haveItem = this.items.some(ele => {
      if (ele.code == e.value) {
        // console.log(ele)
        data.itemDesc = ele.flag
        data.itemId = ele.code
        if(data.isserialcontrol !== ele._data.isserialcontrol) {
          data.quantity = 0;
          data.serials = [];
        }
        data.isserialcontrol = ele._data.isserialcontrol;
        data.itemCode = ele.itemCode;
        return true
      }else{
        return false
      }
    })
    if(!haveItem){
      data.isserialcontrol = null
      data.itemDesc = null
      data.itemId = null
      data.itemCode = null;
      data.quantity = 0;
      data.serials = [];
    }
  }

  adjustmentItemChange(e, data, items) {
    items.some(ele => {
      if (ele.code == e.value) {
        // console.log(ele)
        data.itemDesc = ele.flag//this.itemDesc
        data.itemId = ele.code//this.itemId

        return true
      }else{
        return false
      }
    })

  }


  edit(d) {
    // console.log(this)
    // this.items = JSON.parse(localStorage.getItem("SKU"))
    this.channels = JSON.parse(localStorage.getItem("SHOP"))
    this.channels2 = JSON.parse(localStorage.getItem("WAREHOUSE"))
    this.itemValue = d['itemId']
    this.channelValue = d['fromChannel'] + ''
    this.channelValue2 = d['toWareHouse'] + ''
    this.qty = d['qty']
    this.status = d['status']
    if (d.id) {
      d.newid = d.id
      delete d.id
    }
  }

  cancel(d) {
    if (d.newid) {
      d.id = d.newid
      delete d.newid
    }
  }

  clear() {
    // clear
  }

  delete(data) {
    if(this.loadingSwitch) return;
    if (data.id) {
      this.m.confirm({
        message: `Do you want to delete the transaction record?`,
        header: 'Delete Confirmation',
        accept: () => {
          this.commonService.httpPost(URLDICT.STOCK_RETURN_DELETE, data)
          // HttpHelper.post(URLDICT.STOCK_RETURN_DELETE, data)
          .then(res => {
            if (res.code === '000') {
              // this.search()
              this.lineData.splice(this.lineData.indexOf(data), 1)
            } else {
              // this.n.error("System", "Submit Fail!")
              this.showMessage('error','System', 'Submit Fail!')
            }
          }).catch(e => {
            // this.n.error("System", e.toString())
            console.error(e)
            this.showMessage('error','System', e.toString())
          })
        }
      });
    }else{
      this.m.confirm({
        message: `Do you want to delete this transaction record?`,
        header: 'Delete Confirmation',
        accept: () => {
          this.lineData.splice(this.lineData.indexOf(data), 1)
        }
      });
    }
    this.editing = false;
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    //restore original order
    // console.log(this.selectedColumns)
    this._selectedColumns = this.cols.filter(col => val.includes(col));
    this.selectedColumnsLength = this._selectedColumns.length;
    this.rowexpansionColspan = this.selectedColumnsLength + 2;
  }
  selectedColumnsLength = 3;
  rowexpansionColspan = 5

  timestampToTime(timestamp) {
    return CommonMethod.timestampToTime(timestamp)
  }

  line = {}
  cloneLine = {}

  onRowEditInit(line) {
    this.cloneLine[line.id] = {...line};
  }

  onRowEditSave(line) {
    delete this.cloneLine[line.id];
  }

  onRowEditCancel(line, index: number) {
    this.line[index] = this.cloneLine[line.id];
    delete this.line[line.id];
  }

  onRowDelete(index1, index2) {
    // console.log(index1, index2)
    this.lineData[index1].serials.splice(index2, 1)
  }


  create() {
    // this.lineData.push({itemId:this.skuValue,quantity:this.quantity})
    if(this.loadingSwitch) return;
    let defaultReason
    if((this.selectedType == this.SELECTEDTYPE.ADJUSTMENT || this.selectedType == this.SELECTEDTYPE.ADJUSTMENTHKTHOME) && this.orderStatusId == STATUSID.ADJ_DRAFT){
      defaultReason = this._reasonOptions.find(opt=>opt.reasonCode=='WO')
    }
    if((this.selectedType == this.SELECTEDTYPE.COLORADJUSTMENT) && this.orderStatusId == STATUSID.ADJ_DRAFT){
      defaultReason = this._reasonOptions.find(opt=>opt.reasonCode=='CA')
    }
    this.lineData.push({
      // lineNumber: this.lineNumber,
      //trxTypeId: '6',
      itemId: this.itemValue,
      quantity: this.qty?this.qty:0,
      serials: [],
      ...defaultReason?{
        reason: defaultReason.id,
        finalReason: defaultReason.id,
      }:{}
    })
    this.editing = true
    return this.lineData[this.lineData.length-1]
  }

  createLine(index) {

    this.lineData[index].serials.push({serialNumber: ''})
  }

  save(d=null) {
    this.loadingSwitch++

    this.data['dmDnNumber'] = this.deliveryNote
    this.data['orderNumber'] = this.orderNumber
    this.data['orderTypeId'] = this.orderTypeItem
    this.data['fromChannelId'] = this.fromChannel
    this.data['toChannelId'] = this.toWareHouse
    this.data['fromStatusNature'] = this.statusItem1
    this.data['toStatusNature'] = this.statusItem2
    this.data['orderStatusCode'] = this.orderStatusId //?.code // orderStatusItem already save as code, sys_def_lookup_id
    this.data['remarks'] = this.remark
    this.data['creationDate'] = this.creationDate
    this.data['updateDate'] = this.updateDate
    this.data['noCartonBox'] = this.noCartonBox
    let _lineData = this.lineData.map((line, idx)=>{
      return {
        ...line,
        lineNumber: idx+1,
        reasonId: line.reason,
        finalReasonId: line.finalReason
      }
    })
    this.data['line'] = _lineData
    // console.log(this.data);

    // this.n.info("Transfer", "Save Successful")
    d && this.showMessage('info','Transfer', 'Save Successful')
    this.loadingSwitch--
    this.editing = false
  }
  validationData(): boolean{
    this.save()
    // console.log('validationData: ', this.data)
    if(
      this.selectedType !== SELECTEDTYPE.ADJUSTMENT &&
      this.selectedType !== SELECTEDTYPE.ADJUSTMENTHKTHOME &&
      this.selectedType !== SELECTEDTYPE.COLORADJUSTMENT &&
      this.selectedType !== SELECTEDTYPE.ASSORTMENT &&
      this.selectedType !== SELECTEDTYPE.NOPREPLENISHMENT &&
      this.selectedType !== SELECTEDTYPE.RETURN &&
      this.selectedType !== SELECTEDTYPE.RETURNWOCOURIER &&
      this.selectedType !== SELECTEDTYPE.WAREHOUSEHOMED &&
      this.selectedType !== SELECTEDTYPE.CHANNELHOMED &&
      this.selectedType !== SELECTEDTYPE.WAREHOUSEHOMEDRETURN &&
      this.selectedType !== SELECTEDTYPE.CHANNELHOMEDRETURN
    ){
      if (!this.data['fromChannelId'] || !this.data['fromStatusNature'] || !this.data['toChannelId'] || !this.data['toStatusNature'] || !this.data['orderTypeId']) {
        this.showMessage('warn','System', 'Please fill in the content of creation area first!')
        return false
      }
    }
    if((this.selectedType == SELECTEDTYPE.ADJUSTMENT || this.selectedType == SELECTEDTYPE.ADJUSTMENTHKTHOME) && !this.data['fromChannelId']){
      this.showMessage('warn','System', 'Please fill in the content of creation area first!')
      return false
    }
    if(this.selectedType == SELECTEDTYPE.RETURN &&
      (!this.data['fromChannelId'] || !this.data['fromStatusNature'] || !this.data['toChannelId'] || !this.data['orderTypeId'])){
      this.showMessage('warn','System', 'Please fill in the content of creation area first!')
      return false
    }
    if(this.selectedType == SELECTEDTYPE.RETURNWOCOURIER &&
      (!this.data['fromChannelId'] || !this.data['fromStatusNature'] || !this.data['toChannelId'] || !this.data['orderTypeId'])){
      this.showMessage('warn','System', 'Please fill in the content of creation area first!')
      return false
    }
    if(this.selectedType == SELECTEDTYPE.WAREHOUSEHOMED &&
      (!this.data['fromChannelId'] || !this.data['orderTypeId'])){
      this.showMessage('warn','System', 'Please fill in the content of creation area first!')
      return false
    }
    if(this.selectedType == SELECTEDTYPE.CHANNELHOMED &&
      (!this.data['fromChannelId'] || !this.data['fromStatusNature'] || !this.data['orderTypeId'])){
      this.showMessage('warn','System', 'Please fill in the content of creation area first!')
      return false
    }
    if((this.selectedType == SELECTEDTYPE.CHANNELTRANSFER || this.selectedType == SELECTEDTYPE.CHANNELTRANSFERCOURIER) &&
      (this.data['fromChannelId'] && this.data['fromStatusNature'] && this.data['toChannelId'] && this.data['toStatusNature'] && this.data['orderTypeId']) &&
      (this.data['fromChannelId'] == this.data['toChannelId'])
    ){
      let _type = this.orderTypes.find(e=>e.id==this.orderTypeItem)
      this.showMessage('warn','System', `${_type.description} not allow same channel as from, to channel`)
      return false
    }
    // if(this.selectedType == SELECTEDTYPE.RETURN){
    //   let warehouseIdList = this.warehouseList.map(data=>data.id)
    //   if(warehouseIdList.indexOf(this.data['toChannelId'])==-1){
    //     // this.showMessage('warn','System', 'Channel to Warehouse is Selected, Please Select Warehouse in "To" Field!')
    //     // return false
    //   }
    // }
    if ( this.data['line'].length === 0) {
      this.showMessage('warn','System', 'Please create a new transfer order first!')
      return false
    }else{
      for (const item of this.data['line']) {
        if(!item.itemId || !item.quantity){
          this.showMessage('warn','System', 'Please fill in the content of transfer order first!')
          return false
        }
        if(
          this.selectedType == SELECTEDTYPE.ADJUSTMENT
          || this.selectedType == SELECTEDTYPE.ADJUSTMENTHKTHOME
          || this.selectedType == SELECTEDTYPE.COLORADJUSTMENT
        ){
          if(!item.fromStockConditionId){
            this.showMessage('warn','System', 'Please fill in the content of transfer order first!')
            return false
          }
          // if(this.orderStatusId == STATUSID.ADJ_ADJUSTIP1){
          if(item.reason&&this.orderStatusId==STATUSID.ADJ_DRAFT){
            // check when create draft
            let lineReason = this._reasonOptions.find(reason=>reason.id==item.reason)
            if(item.quantity > 0 && lineReason && lineReason.isAllowPositive != 'Y'){
              this.showMessage('warn','System', `Reason: "${lineReason.reasonDescription}" can't input positive quantity!`)
              return false
            }
            if(item.quantity < 0 && lineReason && lineReason.isAllowNegative != 'Y'){
              this.showMessage('warn','System', `Reason: "${lineReason.reasonDescription}" can't input negative quantity!`)
              return false
            }
          }
        }
      }
    }
    let _orderType = this.orderTypes.find(e=>e.code == this.data.orderTypeId);
    if(_orderType.orderTypeCode == SELECTEDTYPE.CHANGECONDITION ){
      if(this.data.fromStatusNature == this.data.toStatusNature){
        this.showMessage('warn','System', 'From Status Condition and To Status Condition should be different!')
        return false
      }
    }
    if(_orderType.orderTypeCode == SELECTEDTYPE.CHANGECONDITION || _orderType.orderTypeCode == SELECTEDTYPE.CHANNELTRANSFER ){
      let haveNonUnique = this.data.line.some((element, idx) => {
        let duplic = this.data.line.find((e, i)=>{
          return e.itemId == element.itemId && idx != i
        })
        if(duplic){return true}else{return false}
      });
      if(haveNonUnique){
        this.showMessage('warn','System', 'Transfer Offer Item Should be unique!')
        return false
      }
    }

    if(
      (
        this.orderTypeItem==ORDERTYPEID.ADJUSTMENT
        || this.orderTypeItem==ORDERTYPEID.ADJUSTMENTHKTHOME
        || this.selectedType == SELECTEDTYPE.COLORADJUSTMENT
      )
      &&(this.orderStatusId==STATUSID.ADJ_DRAFT||this.orderStatusId==STATUSID.ADJ_REVIEWING)
    ){
      let haveEmptyReason = this.data.line.some((element, idx) => {
        return element.reason == null
      })
      if(haveEmptyReason){
        this.showMessage('warn','System', 'Reason Cannot Be Empty')
        return false
      }
    }

    if(
      (
        this.orderTypeItem==ORDERTYPEID.ADJUSTMENT
        || this.orderTypeItem==ORDERTYPEID.ADJUSTMENTHKTHOME
      )
      &&(this.orderStatusId==STATUSID.ADJ_SUBMITTED||this.orderStatusId==STATUSID.ADJ_ADJUSTIP1)
    ){
      let haveEmptyFinalReason = this.data.line.some((element, idx) => {
        return element.finalReason == null
      })
      if(haveEmptyFinalReason){
        this.showMessage('warn','System', 'Final Reason Cannot Be Empty')
        return false
      }
    }

    if(
      (
        this.orderTypeItem==ORDERTYPEID.ADJUSTMENT
        || this.orderTypeItem==ORDERTYPEID.ADJUSTMENTHKTHOME
      )
      &&(this.orderStatusId==STATUSID.ADJ_ADJUSTIP1)){
      let haveRefNo = this.data.internalReference!=null&&this.data.internalReference!=''
      if(!haveRefNo){
        this.showMessage('warn','System', 'Reference Number Cannot Be Empty')
        return false
      }
    }

    if(this.isCheckingReceived){
      let receiveQtyInValid = this.lineData.some(line=>{
        let res = {
          "preQtyActual": line.qtyActual?line.qtyActual:0,
          "preQtyCancelled": line.qtyCancelled?line.qtyCancelled:0,
          "preQtyWriteOff": line.qtyWriteOff?line.qtyWriteOff:0,
        }
        if( line._buffer ){
          if( line._buffer.originQtyActual>0 && line._buffer.originQtyActual > res.preQtyActual ){
            this.showMessage('error','System', 'Received Qty should not less than ' + line._buffer.originQtyActual)
            return true
          }
          if( line._buffer.originQtyCancelled>0 && line._buffer.originQtyCancelled > res.preQtyCancelled ){
            this.showMessage('error','System', 'Cancelled Qty should not less than ' + line._buffer.originQtyCancelled)
            return true
          }
          if( line._buffer.originQtyWriteOff>0 && line._buffer.originQtyWriteOff > res.preQtyWriteOff ){
            this.showMessage('error','System', 'Write-Off Qty should not less than ' + line._buffer.originQtyWriteOff)
            return true
          }
        }
        if(res.preQtyActual + res.preQtyCancelled + res.preQtyWriteOff > line.qty){
          this.showMessage('error','System', 'Sum of Received / Cancelled / Write-Off Qty should not more than Qty')
          return true
          // receiveQtyInValid = true
        }
        if(res.preQtyActual + res.preQtyCancelled + res.preQtyWriteOff != line.qty){
          this.showMessage('error','System', 'Sum of Received / Cancelled / Write-Off Qty should equal to Qty')
          return true
        }
      })
      if(receiveQtyInValid){
        return false
      }
    }

    // color adjustment check sum of qty == 0 (e.g. (+1) + (-1))
    if(this.orderTypeItem==ORDERTYPEID.COLORADJUSTMENT){
      let _totalQty = 0
      this.lineData.forEach(line=>{
        _totalQty += line.quantity
      })
      if(_totalQty!=0){
        this.showMessage('error','System', 'Qty not equal, item with positive qty should have other item with same negative qty')
        return false
      }
      let itemCodeList = []
      let haveDuplicateItem = this.lineData.some(line=>{
        if(itemCodeList.includes(line.itemId)) return true
        itemCodeList.push(line.itemId)
        return false
      })
      if(haveDuplicateItem){
        this.showMessage('error','System', 'Item Should be unique!')
        return false
      }
    }

    // is return without courier && ready to allocate ( is draft )
    if(this.appointmentDateInputEditable){
      if(this.appointmentDate==null){
        this.showMessage('error','System', 'Appointment Date Cannot Be Empty')
        return false
      }
    }

    return true
  }
  //提交数据
  submit(data = this.data) {
    if(this.loadingSwitch) return
    this.loadingSwitch++
    // rename orderStatusItem to orderStatusItemObj since this row use for store the object and edit object.value later
    const orderStatusItemObj = this.queryPannel.ipts.find(item=>item.name==='orderStatusItem')

    const flag = this.validationData()
    if(data.orderTypeId == ORDERTYPEID.ADJUSTMENT || data.orderTypeId == ORDERTYPEID.ADJUSTMENTHKTHOME){
      // manually create adjustment, add internal remarks, internal remarks pattern handle by backend
      data.autoInternalRemark = 'Y'
    }

    if(!flag){
      this.loadingSwitch--
      return
    }
    const orderNum = this.orderTypeItem?.code || this.orderTypeItem
    // const path = {
    //   1: URLDICT.STOCK_CHANNEL_TRANSFERCREATE, // stock adjustment, before save as draft
    //   2: URLDICT.STOCK_CHANNEL_TRANSFERCREATE,
    //   3: URLDICT.STOCK_CHANNEL_TRANSFERCREATE,
    //   5: URLDICT.STOCK_CHANNEL_TRANSFERCREATE,
    //   7: URLDICT.STOCK_TRANSFER_STOCKIN,
    //   16: URLDICT.STOCK_CHANNEL_TRANSFERCREATE,// CHANNEL-CHANNEL
    // };
    const url = URLDICT.STOCK_CHANNEL_TRANSFERCREATE; //path[orderNum]
    if (url) {
      const btn = this.queryPannel.btns.find(item=>item.tag===PANELBTNTAG.SAVEASDRAFT)
      // const btnConfirm = this.queryPannel.btns.find(item=>item.tag===PANELBTNTAG.UPDATETONEXTSTATUS)
      btn.disabled = true;
      this.setUpdateBtnDisabled(true)
      // btnConfirm.disabled = true;
      // //const btnClear = this.queryPannel.btns.find(item=>item.tag===PANELBTNTAG.CLEARALL)
      const btnEdit = this.queryPannel.btns.find(item=>item.tag===PANELBTNTAG.EDIT)
      const btnClear = this.queryPannel.btns.find(item=>item.tag===PANELBTNTAG.CLEARALL)
      console.log('before save', data)
      this.loadingSwitch++
      this.stockOrderService.createStockOrder(data).pipe(
      ).subscribe(res => {
        this.loadingSwitch--;
        btn.disabled = false;
        this.setUpdateBtnDisabled(false)
        // btnConfirm.disabled = false;
        if (res.code === '000') {
          if(res.data[0]&&res.data[0].orderNumber){
            const orderNumberItem = this.queryPannel.ipts.find(item=>item.name==='orderNumber')
            this.orderNumber = res.data[0].orderNumber
            orderNumberItem.value = this.orderNumber;
          }
          this.headId = res.data[0].headId;
          const title = {
            2: 'Stock Return',
            5: 'Channel Transfer',
            7: 'Channel Transfer'
          }
          this.showMessage('success', this.title, 'Save Success')
          this.proceedFlag = false
          this.r.navigate([], { relativeTo: this.p, queryParams: { headId: this.headId }, queryParamsHandling: 'merge' });
        } else {
          if(!res.error){
            res.error = res.msg || res.data[0].errorMsg
          }
          this.showMessage('error','System', res.error)
        }
      },e=>{
        this.loadingSwitch--;
        btn.disabled = false;
        this.setUpdateBtnDisabled(false)
        // btnConfirm.disabled = false;
        this.showMessage('error','System', e.toString())
      },() => {
        btn.disabled = false;
        this.setUpdateBtnDisabled(false)
        // btnConfirm.disabled = false;
      })

    } else {
      // this.loadingSwitch--
    }
    this.loadingSwitch--
  }


  loadFileDetailsByLineIdForVerify(lineid, moduleName='StockOrderLine'){
    return this.stockCommonService.getEdmsFileIds({
      "moduleName": moduleName,
      "relatedId": lineid
    })
    .pipe(
      map(res=>{
        if(res.code!='000') throw res.msg || res.message
        return res
      }),
    )
  }
  loadFileDetailsByIdForVerify(lineid, moduleName){
    return this.stockCommonService.getEdmsFileIds({
      "moduleName": moduleName,
      "relatedId": lineid
    })
    .pipe(
      map(res=>{
        if(res.code!='000') throw res.msg || res.message
        return res
      }),
    )
  }

  checkReasonAttachment(){
    let idList = this.lineData.filter(e=>e.reason==REASON.SE).map(e=>e.lineId)
    //console.log(idList.length)
    if(idList.length>0){
      let fileLisApiArr:Observable<any>[] =[]
      for (let index = 0; index < idList.length; index++) {
        fileLisApiArr.push(this.loadFileDetailsByIdForVerify(idList[index],'StockOrderLine'))
      }
      return this.loadFileDetailsByIdForVerify(this.headId,'StockOrder').pipe(
        switchMap(
          (_res)=>{
            if(_res.data.length==0){
              return forkJoin(fileLisApiArr).pipe(tap((res:any[])=>{
                if(res.filter(e=>e.data.length==0).length>0){
                  throw ORDER_CONFIRM_MSG.ATTACHMENT_ADJ_SE_ERROR;
                }
              }))
            }else{
              return of(null)
            }
          }
        )
      )
    }else{
      return of(null)
    }
  }

  confirmUpdate(data){
    if(this.loadingSwitch) return
    this.loadingSwitch++

    if(
      this.selectedType==SELECTEDTYPE.COLORADJUSTMENT &&
      ( this.orderStatusId==STATUSID.ADJ_REVIEWING || this.orderStatusId==STATUSID.ADJ_DRAFT )
    ){
      this.loadingSwitch++
      this.getNetAmountObs().subscribe(res=>{
        this.loadingSwitch--
        this.doConfirmUpdate(data)
      },err=>{
        this.loadingSwitch--
        this.showMessage('error','System', err)
      })
      this.loadingSwitch--
      return
    }

    if(
      (this.selectedType==SELECTEDTYPE.ADJUSTMENT || this.selectedType == SELECTEDTYPE.ADJUSTMENTHKTHOME) &&
      ( this.orderStatusId==STATUSID.ADJ_SUBMITTED||this.orderStatusId==STATUSID.ADJ_DRAFT||this.orderStatusId==STATUSID.ADJ_REVIEWING)
    ){
      // adjustment get net amount before submit
      this.loadingSwitch++
      forkJoin([
        this.getNetAmountObs(),
        this.checkReasonAttachment()
      ]).subscribe(res=>{
        this.loadingSwitch--
        this.doConfirmUpdate(data)
      },err=>{
        this.loadingSwitch--
        this.showMessage('error','System', err)
      })
      this.loadingSwitch--
      return
    }
    this.doConfirmUpdate(data)

    this.loadingSwitch--
  }

  // doConfirmUpdate() => confirmation when
  // adjustment click adjust
  // color adjustment click review and net amount not 0
  // color adjustment click adjust (differ msg when net amount not 0)
  doConfirmUpdate(data){
    let needConfirm =
      (this.selectedType==SELECTEDTYPE.ADJUSTMENT&&this.orderStatusId==STATUSID.ADJ_ADJUSTIP2&&this.btnLabel==BTN_LABEL.ADJ_ADJUST) ||
      (this.selectedType==SELECTEDTYPE.ADJUSTMENTHKTHOME&&this.orderStatusId==STATUSID.ADJ_ADJUSTIP2&&this.btnLabel==BTN_LABEL.ADJ_ADJUST) ||
      (this.selectedType==SELECTEDTYPE.COLORADJUSTMENT&&this.orderStatusId==STATUSID.ADJ_DRAFT&&this.netAmtQty!=0) ||
      (this.selectedType==SELECTEDTYPE.COLORADJUSTMENT&&this.orderStatusId==STATUSID.ADJ_REVIEWING)
    if(needConfirm){
      let msg = `Confirm to ${this.btnLabel}?`;
      let acceptFunc = () => {
        this.update(data)
      }
      if(
        (this.selectedType==SELECTEDTYPE.ADJUSTMENT || this.selectedType==SELECTEDTYPE.ADJUSTMENTHKTHOME)
        &&this.orderStatusId==STATUSID.ADJ_ADJUSTIP2&&this.btnLabel==BTN_LABEL.ADJ_ADJUST&&this.sourceTxnType==TAKE_ADJUSTMENT_TXN_TYPE
      ){
        // stock take adjustment complete
        msg = CommonMethod.replacePatten(ORDER_CONFIRM_MSG.ADJ_TAKE_COMPLETED, [this.sourceTxnRefHeaderNo])
      }else if(
        (this.selectedType==SELECTEDTYPE.ADJUSTMENT || this.selectedType==SELECTEDTYPE.ADJUSTMENTHKTHOME)
        &&this.orderStatusId==STATUSID.ADJ_ADJUSTIP2&&this.btnLabel==BTN_LABEL.ADJ_ADJUST
      ){
        // adjustment complete
        msg = ORDER_CONFIRM_MSG.ADJ_COMPLETED
      }else if(this.selectedType==SELECTEDTYPE.COLORADJUSTMENT&&this.orderStatusId==STATUSID.ADJ_DRAFT&&this.netAmtQty!=0){
        // net amt != 0
        msg = ORDER_CONFIRM_MSG.COLORADJ_NETAMT
      }else if(this.selectedType==SELECTEDTYPE.COLORADJUSTMENT&&this.orderStatusId==STATUSID.ADJ_REVIEWING){
        if(this.netAmtQty!=0){
          msg = ORDER_CONFIRM_MSG.COLORADJ_NETAMT
          acceptFunc = () => {
            // this.updateOrderDetail()
            let _data = JSON.parse(JSON.stringify(this.data))
            _data.internalRemarks = 'color adjustment with MAP amount variance'
            this.loadingSwitch++
            this.updateOrderDetail(_data).subscribe(res=>{
              this.loadingSwitch--
              if(res.code == '000'){
                let internalRemarksIpt = this.getQueryIptByLabel(TITLE.internalRemarks)
                if(internalRemarksIpt){
                  internalRemarksIpt.value = _data.internalRemarks
                }
              }
              this.update(data)
            },err=>{
              // this.showMessage('error','System', err.toString())
              this.loadingSwitch--
            })
          }
        }else{
          msg = ORDER_CONFIRM_MSG.COLORADJ_CONFIRM
        }
      }
      this.m.confirm({
        message: msg,
        header: 'Confirmation',
        accept: acceptFunc,
      });
    }else{
      this.update(data)
    }
  }

  //更新状态
  update(data) {
    // console.log("ssss:"+JSON.stringify(this.updateStatusData))
    const flag = this.validationData()
    if(!flag){
      return
    }
    // const path = {
    //   1: URLDICT.STOCK_CHANNEL_TRANSFER_UPDATE,
    //   2: URLDICT.STOCK_CHANNEL_TRANSFER_UPDATE,
    //   3: URLDICT.STOCK_CHANNEL_TRANSFER_UPDATE,
    //   4: URLDICT.STOCK_CHANNEL_TRANSFER_UPDATE,
    //   5: URLDICT.STOCK_CHANNEL_TRANSFER_UPDATE,
    //   7: URLDICT.STOCK_TRANSFER_STOCKIN_UPDATE,
    //   16: URLDICT.STOCK_TRANSFER_STOCKIN_UPDATE,
    // };
    let status = this.orderStatus.find(status=>status.code == this.orderStatusId)
    if(
      (
        this.selectedType == SELECTEDTYPE.RETURN ||
        // this.selectedType == SELECTEDTYPE.RETURNWOCOURIER ||
        this.selectedType == SELECTEDTYPE.CHANNELTRANSFERCOURIER
      )
      && status?.name == 'Draft'
    ) {
      this.loadingSwitch++
      this.stockOrderService.checkBalanceReserve(this.headId).subscribe(res=>{
        this.loadingSwitch--;
        if(res.code=="000"){
          if(res.data[0].canreserve=='true'){
            this.r.navigate(["main", "stock", "cas-appointment"], {queryParams: {headId: this.headId}});
          }else{
            this.showMessage('error','System', `Balance not enough to reserve`)
          }
        }
      },e=>{
        this.loadingSwitch--;
        this.showMessage('error','System',`${e}`)
      })
      return
    }

    // const orderNum = this.orderTypeItem?.code || this.orderTypeItem
    // const url = path[orderNum]
    const url = URLDICT.STOCK_CHANNEL_TRANSFER_UPDATE; // all order status update using "transfer-order-status" api

    if (url) {
      // const orderStatusItem = this.queryPannel.ipts.find(item=>item.name==='orderStatusItem')
      // const btn = this.queryPannel.btns.find(item=>item.tag === PANELBTNTAG.UPDATETONEXTSTATUS)
      // //const cBtn = this.queryPannel.btns.find(item=>item.tag === PANELBTNTAG.CLEARALL)
      // btn.disabled = true;
      this.setUpdateBtnDisabled(true)
      // const btnEdit = this.queryPannel.btns.find(item=>item.tag===PANELBTNTAG.EDIT)

      this.genUpdateStatusDataForUpdate()

      this.loadingSwitch++
      let updateObs = of(null)

      // return without courier, create home delivery then update order status
      if(this.appointmentDateInputEditable){
        updateObs = updateObs.pipe(
          switchMap(res=>{
            return this.stockOrderService.checkBalanceReserve(this.headId).pipe(map(res=>{
              if(res.code=="000"){
                if(res.data[0].canreserve=='true'){
                  return res
                }
              }
              let err = res.message || res.msg;
              if(err.length<=0||!err) err = `Balance not enough to reserve`
              throw err || 'An Unexpected Error Occurred'
            }))
          }),
          switchMap(res=>{
            return this.stockOrderService.createHomeDeliveryRequest(this.headId, this.appointmentDate)
            // .pipe(map(res=>{
            //   if(res.code=='000'){
            //     try{
            //       // for export dn file name when create > allocate in same session
            //       // sourceTxnRefHeaderNo only load when load order data with order status after allocate
            //       // create > allocate in same session make component.sourceTxnRefHeaderNo undefined after allocate
            //       // wip: may be reload data with headerid after update status only for the ref no
            //       if(res.data[0].data[0].data[0].deliveryId) this.sourceTxnRefHeaderNo = res.data[0].data[0].data[0].deliveryId
            //       if(res.data[0].data[0].data[0].deliveryNumber) this.sourceTxnRefHeaderNo = res.data[0].data[0].data[0].deliveryNumber
            //     }catch(e){
            //       console.error(e)
            //     }
            //   }
            //   return res
            // }))
          })
        )
      }

      // update order status api call
      updateObs = updateObs.pipe(
        switchMap(res=>{
          return this.stockOrderService.updateStockOrderStatus(this.updateStatusData)
        })
      )

      // order status update subscribe
      updateObs.subscribe(res=>{
        // btn.disabled = false;
        this.setUpdateBtnDisabled(false)
        this.loadingSwitch--;
        if (res.code === '000') {
          this.handleUpdateResult(res)
          if(res.data&&res.data[0]&&res.data[0].warningMsg){
            this.showMessage('warn','System', res.data[0].warningMsg)
          }
          this.loadDataByHeadId(this.headId)
        } else {
          if(!res.error){
            res.error = res.msg || res.data[0].errorMsg
          }
          this.loadDataByHeadId(this.headId)
          this.showMessage('error','System', res.error)
        }
      },err=>{
        this.loadingSwitch--;
        console.error(err)
        // btn.disabled = false;
        this.setUpdateBtnDisabled(false)
        this.loadDataByHeadId(this.headId)
        this.showMessage('error','System', err.toString())
      },()=>{
        // btn.disabled = false;
        this.setUpdateBtnDisabled(false)
      })

      // HttpHelper.put(url, this.updateStatusData).then(res => {
      //   btn.disabled = false;
      //   if (res.code === '000') {
      //     this.handleUpdateResult(res)
      //     if(res.data&&res.data[0]&&res.data[0].warningMsg){
      //       this.showMessage('warn','System', res.data[0].warningMsg)
      //     }
      //   } else {
      //     if(!res.error){
      //       res.error = res.msg || res.data[0].errorMsg
      //     }
      //     this.showMessage('error','System', res.error)
      //   }
      // }).catch(e => {
      //   console.error(e)
      //   btn.disabled = false;
      //   this.showMessage('error','System', e.toString())
      // }).finally(
      //   () => {
      //     this.loadingSwitch--;
      //     btn.disabled = false;
      //   }
      // )
    } else {
      // this.loadingSwitch--
    }
  }

  return() {
    if(this.loadingSwitch) return
    this.loadingSwitch++
    if(!this.showReturn){
      this.loadingSwitch--
      return
    }
    const flag = this.validationData()
    if(!flag){
      this.loadingSwitch--
      return
    }
    const url = URLDICT.STOCK_CHANNEL_TRANSFER_UPDATE

    if (url) {
      // const btn = this.queryPannel.btns.find(item=>item.tag === PANELBTNTAG.UPDATETONEXTSTATUS)
      // //const cBtn = this.queryPannel.btns.find(item=>item.tag === PANELBTNTAG.CLEARALL)
      // btn.disabled = true;
      this.setUpdateBtnDisabled(true)
      // this.genUpdateStatusDataForUpdate()
      const orderNum = this.orderTypeItem?.code || this.orderTypeItem
      let _updateStatusData = {
        orderNumber: this.orderNumber,
        operation: ORDER_STATUS_UPDATE_OPERATION.RETURN,
        updateType: orderNum,
      }
      this.loadingSwitch++
      this.commonService.httpPut(url, _updateStatusData)
      // HttpHelper.put(url, _updateStatusData)
      .then(res => {
        // btn.disabled = false;
        this.setUpdateBtnDisabled(false)
        if (res.code === '000') {
          this.handleUpdateResult(res)
        } else {
          if(!res.error){
            res.error = res.msg || res.data[0].errorMsg
          }
          this.showMessage('error','System', res.error)
        }
      }).catch(e => {
        console.error(e)
        // btn.disabled = false;
        this.setUpdateBtnDisabled(false)
        this.showMessage('error','System', e.toString())
      }).finally(
        () => {
          this.loadingSwitch--;
          // btn.disabled = false;
          this.setUpdateBtnDisabled(false)
        }
      )

    } else {
      // this.loadingSwitch--
    }
    this.loadingSwitch--
  }

  genUpdateStatusDataForUpdate(){
    const orderNum = this.orderTypeItem?.code || this.orderTypeItem
    this.updateStatusData.orderNumber = this.orderNumber
    // update into which status per type per button label
    switch(orderNum){
      case ORDERTYPEID.CHANNELTRANSFER:
      case ORDERTYPEID.CHANGECONDITION:
      case ORDERTYPEID.CHANNELTRANSFERCOURIER:
        switch(this.btnLabel){
          case BTN_LABEL.ALLOCATE:
            this.updateStatusData.operation = ORDER_STATUS_UPDATE_OPERATION.ALLOCATED
            break
          case BTN_LABEL.TRANSFER:
            this.updateStatusData.operation = ORDER_STATUS_UPDATE_OPERATION.TRANSFERRED
            break
          case BTN_LABEL.RECEIVE_FOR_INSPECTION:
            this.updateStatusData.operation = ORDER_STATUS_UPDATE_OPERATION.RECEIVED
            break
          case BTN_LABEL.STOCK_IN:
            this.updateStatusData.operation = ORDER_STATUS_UPDATE_OPERATION.COMPLETED
            break
        }
        break;
      case ORDERTYPEID.ADJUSTMENT:
      case ORDERTYPEID.ADJUSTMENTHKTHOME:
        switch(this.btnLabel){
          case BTN_LABEL.ADJ_REVIEW:
            this.updateStatusData.operation = ORDER_STATUS_UPDATE_OPERATION.ADJ_REVIEWING
            break
          case BTN_LABEL.ADJ_SUBMIT:
            this.updateStatusData.operation = ORDER_STATUS_UPDATE_OPERATION.ADJ_SUBMITTED
            break
          case BTN_LABEL.ADJ_VERIFY:
            this.updateStatusData.operation = ORDER_STATUS_UPDATE_OPERATION.ADJ_VERIFIED
            break
          case BTN_LABEL.ADJ_TOADJUSTIP1:
            this.updateStatusData.operation = ORDER_STATUS_UPDATE_OPERATION.ADJ_ADJUSTIP1
            break
          case BTN_LABEL.ADJ_FIN_REVIEW:
            this.updateStatusData.operation = ORDER_STATUS_UPDATE_OPERATION.ADJ_ADJUSTIP2
            break
          case BTN_LABEL.ADJ_ADJUST:
            this.updateStatusData.operation = ORDER_STATUS_UPDATE_OPERATION.ADJ_COMPLETED
            break
          case BTN_LABEL.ADJ_REWORK:
            this.updateStatusData.operation = ORDER_STATUS_UPDATE_OPERATION.ADJ_DRAFT
            break
        }
        break;
      case ORDERTYPEID.COLORADJUSTMENT:
        switch(this.btnLabel){
          case BTN_LABEL.ADJ_REVIEW:
            this.updateStatusData.operation = ORDER_STATUS_UPDATE_OPERATION.ADJ_REVIEWING
            break
          case BTN_LABEL.ADJ_ADJUST:
            this.updateStatusData.operation = ORDER_STATUS_UPDATE_OPERATION.ADJ_COMPLETED
            break
          case BTN_LABEL.ADJ_REWORK:
            this.updateStatusData.operation = ORDER_STATUS_UPDATE_OPERATION.ADJ_DRAFT
            break
        }
        break;
      case ORDERTYPEID.ASSORTMENT:
      case ORDERTYPEID.NOPREPLENISHMENT:
        switch(this.btnLabel){
          case BTN_LABEL.RECEIVE_FOR_INSPECTION:
            this.updateStatusData.operation = ORDER_STATUS_UPDATE_OPERATION.RECEIVED
            break
          case BTN_LABEL.STOCK_IN:
            this.updateStatusData.operation = ORDER_STATUS_UPDATE_OPERATION.COMPLETED
            break
        }
        break;
      case ORDERTYPEID.RETURN:
        switch(this.btnLabel){
          case BTN_LABEL.ALLOCATE:
            this.updateStatusData.operation = ORDER_STATUS_UPDATE_OPERATION.ALLOCATED
            break
          case BTN_LABEL.TRANSFER:
            this.updateStatusData.operation = ORDER_STATUS_UPDATE_OPERATION.TRANSFERRED
            break
          case BTN_LABEL.COMPLETE:
            this.updateStatusData.operation = ORDER_STATUS_UPDATE_OPERATION.COMPLETED
            break
        }
        break;
      case ORDERTYPEID.WAREHOUSEHOMED:
        if(this.btnLabel==BTN_LABEL.COMPLETE) this.updateStatusData.operation = ORDER_STATUS_UPDATE_OPERATION.COMPLETED
        // switch(this.btnLabel){
        //   case BTN_LABEL.COMPLETE:
        //     break
        // }
        break;
      case ORDERTYPEID.CHANNELHOMEDRETURN:
        switch(this.btnLabel){
          case BTN_LABEL.RECEIVE_FOR_INSPECTION:
            this.updateStatusData.operation = ORDER_STATUS_UPDATE_OPERATION.RECEIVED
            break
          case BTN_LABEL.COMPLETE:
            this.updateStatusData.operation = ORDER_STATUS_UPDATE_OPERATION.COMPLETED
            break
        }
        break;
      case ORDERTYPEID.WAREHOUSEHOMEDRETURN:
        if(this.btnLabel==BTN_LABEL.COMPLETE) this.updateStatusData.operation = ORDER_STATUS_UPDATE_OPERATION.COMPLETED
        // switch(this.btnLabel){
        //   case BTN_LABEL.COMPLETE:
        //     break
        // }
        break;
      default:
        switch(this.btnLabel){
          case BTN_LABEL.ALLOCATE:
            this.updateStatusData.operation = ORDER_STATUS_UPDATE_OPERATION.ALLOCATED
            break
          case BTN_LABEL.TRANSFER:
            this.updateStatusData.operation = ORDER_STATUS_UPDATE_OPERATION.TRANSFERRED
            break
          case BTN_LABEL.RECEIVE_FOR_INSPECTION:
            this.updateStatusData.operation = ORDER_STATUS_UPDATE_OPERATION.RECEIVED
            break
          case BTN_LABEL.STOCK_IN:
            this.updateStatusData.operation = ORDER_STATUS_UPDATE_OPERATION.COMPLETED
            break
          case BTN_LABEL.COMPLETE:
            this.updateStatusData.operation = ORDER_STATUS_UPDATE_OPERATION.COMPLETED
            break
        }
        break;
    }

    this.updateStatusData.updateType = orderNum
    if(this.isCheckingReceived){
      let dataLine = this.lineData.map(line=>{
        let res = {
          "stockOrderLineId": line.lineId,
          "preQtyActual": line.qtyActual?line.qtyActual:0,
          "preQtyCancelled": line.qtyCancelled?line.qtyCancelled:0,
          "preQtyWriteOff": line.qtyWriteOff?line.qtyWriteOff:0,
          "serials": []
        }
        if( line._buffer ){
          if( line._buffer.originQtyActual>0 && line._buffer.originQtyActual > res.preQtyActual ){
            this.showMessage('error','System', 'Received Qty should not less than ' + line._buffer.originQtyActual)
            return
          }
          if( line._buffer.originQtyCancelled>0 && line._buffer.originQtyActual > res.preQtyCancelled ){
            this.showMessage('error','System', 'Cancelled Qty should not less than ' + line._buffer.originQtyCancelled)
            return
          }
          if( line._buffer.originQtyWriteOff>0 && line._buffer.originQtyActual > res.preQtyWriteOff ){
            this.showMessage('error','System', 'Write-Off Qty should not less than ' + line._buffer.originQtyWriteOff)
            return
          }
        }
        line.serials.forEach(serial => {
          if(
            (serial.received||serial.cancelled||serial.writeOff) &&
            !serial.skipCheckForReceive
          ){
            let yesValue = 'Y';
            let _serial: any = {
              serial: serial.serial,
            }
            if(serial.received){
              _serial.preReceived = yesValue;
            }else if(serial.cancelled){
              _serial.preCancelled = yesValue;
            }else if(serial.writeOff){
              _serial.preWriteOff = yesValue;
            }
            res.serials.push(_serial)
          }
        });
        return res;
      })
      this.updateStatusData.line = dataLine
    }else if(this.orderStatusId==STATUSID.TRANSFERRED){
      // transferred order status update require line, preqtyactual
      let dataLine = this.lineData.map(line=>{
        let res = {
          "stockOrderLineId": line.lineId,
          "preQtyActual": line.quantity,
          "serials": []
        }
        if(line.serials){res.serials = line.serials}
        return res;
      })
      this.updateStatusData.line = dataLine
    }
  }

  handleUpdateResult(res){
    let orderType = this.orderTypes.find(item=>item.id === this.orderTypeItem)
    // const orderNum = this.orderTypeItem?.code || this.orderTypeItem
    // const title = {
    //   1: 'Adjust',
    //   2: 'Stock Return',
    //   3: 'Change Stock Condition',
    //   4: 'Warehouse to Channel',
    //   5: 'Channel Transfer',
    //   7: 'Channel Stockin'
    // }
    // const info = this.btnLabel === BTN_LABEL.CONFIRM ? 'Confirm Success' :  'Completed'
    this.showMessage('success', orderType.label, `${this.btnLabel} Success`)

    if(
      (
        this.selectedType == SELECTEDTYPE.ASSORTMENT ||
        this.selectedType == SELECTEDTYPE.NOPREPLENISHMENT ||
        this.selectedType == SELECTEDTYPE.CHANNELTRANSFER
      ) &&
      this.btnLabel == BTN_LABEL.STOCK_IN
    ) {
      // stockin finish and order type is "warehouse to channel"
      this.allocateCreate()
      // this.showMessage('info', "", "Redirecting to Stock Allocation");
      // this.r.navigate(["main", "transfer_order", "allocate_create"], {queryParams: {headId: this.headId}});
      return
    }

    const orderStatusItem = this.queryPannel.ipts.find(item=>item.name==='orderStatusItem')
    const btn = this.queryPannel.btns.find(item=>item.tag === PANELBTNTAG.UPDATETONEXTSTATUS)
    const btnEdit = this.queryPannel.btns.find(item=>item.tag===PANELBTNTAG.EDIT)
    this.orderStatusObject=this.orderStatus.find(item =>
      item.code == res.data[0].statusId
    )
    this.orderStatusId = this.orderStatusObject.code
    this.isNew = false
    this.handleStatusFromData(res.data[0])

    if(this.orderStatusId == STATUSID.WAITING_TO_COMPLETE){
      this.handleWaitingToComplete()
    }

    orderStatusItem.value =  this.orderStatusId;

    this.setDisabled()
    btn.title = this.btnLabel
  }

  allocateCreate(){
    this.showMessage('info', "", "Waiting for order complete and redirect to Stock Allocation");
    this.loadingSwitch++
    this.allocateCreateTimer = setTimeout(()=>{
      // this.loadingSwitch--
      // this.loadingSwitch++
      this.getCurrentStatusIsCompleted().subscribe(res=>{
        this.loadingSwitch--
        if(res){
          this.showMessage('info', "", "Redirecting to Stock Allocation");
          this.r.navigate(["main", "transfer_order", "allocate_create"], {queryParams: {headId: this.headId}});
        }else{
          this.allocateCreate()
        }
      }, err=>{
        this.loadingSwitch--
      })
    },30000)
  }

  getCurrentStatusIsCompleted(){
    return this.stockCommonService.getOrderByHeadId(this.headId).pipe(map(res=>{
      return res?.orderStatus == STATUSID.FAIL_TO_COMPLETE || res?.orderStatus == STATUSID.COMPLETED || res?.orderStatus == STATUSID.ADJ_COMPLETED
    }))
  }

  customSort(e){
    // custom sort
  }
  /**
   * 返回上一页
   */
  @action back() {
    // window.history.back()
    let searchParams = this.commonStore.getObject(CONFIG.SEARCHPARAMSKEY.STOCKORDER)
    if(!searchParams){searchParams = {}}
    this.r.navigate(['main', 'transfer_order', 'search'], {queryParams: {...searchParams}})
  }
  showMessage(severity, summary, detail){
    // console.error(`trace showMessage: ${severity} | ${summary} | ${detail}`)
    this.n.add({severity, summary, detail});
  }


  setTableColBySelectedType(selectedType = this.selectedType){
    switch(selectedType){
      case SELECTEDTYPE.CHANGECONDITION:
        this.cols = this.originColList.filter(e => ['itemCode', 'item', 'qty', 'remark'].indexOf(e.header) !== -1)
        this.selectedColumns = this.cols;
        this.isQtyPositive = true;
        break;
      case SELECTEDTYPE.CHANNELTRANSFER:
      case SELECTEDTYPE.CHANNELTRANSFERCOURIER:
        this.cols = this.originColList.filter(e => ['itemCode', 'item', 'qty', 'qtyActual', 'qtyCancelled', 'qtyWriteOff', 'remark'].indexOf(e.header) !== -1)
        this.selectedColumns = this.cols;
        this.isQtyPositive = true;
        break;
      case SELECTEDTYPE.ASSORTMENT:
      case SELECTEDTYPE.NOPREPLENISHMENT:
        this.cols = this.originColList.filter(e => ['itemCode', 'item', 'qty', 'qtyActual', 'qtyCancelled', 'qtyWriteOff', 'remark', 'deliveryBatchNo', 'replenishReference', 'isoNo'].indexOf(e.header) !== -1)
        this.selectedColumns = this.cols;
        this.isQtyPositive = true;
        break;
      case SELECTEDTYPE.ADJUSTMENT:
      case SELECTEDTYPE.ADJUSTMENTHKTHOME:
      case SELECTEDTYPE.COLORADJUSTMENT:
        this.cols = this.originColList.filter(e => ['statusCondition', 'itemCode', 'item', 'qty', 'reason', 'finalReason', 'remark'].indexOf(e.header) !== -1)
        this.selectedColumns = this.cols;
        this.isQtyPositive = false;
        break;
      default:
        this.cols = this.originColList.filter(e => ['itemCode', 'item', 'qty', 'remark'].indexOf(e.header) !== -1)
        this.selectedColumns = this.cols;
        this.isQtyPositive = false;
    }
  }
  cleanIptsData() {
    this.fromChannel = null
    this.statusItem1= null
    this.toChannelShowValue = null
    this. statusItem2 = null
    this.remark = null
    this.noCartonBox = null
    const list = [ 'from', 'to','fromStatusNature', 'toStatusNature', 'remark', 'noCartonBox']
    this.queryPannel.ipts.forEach((item) => {
      if (list.includes(item.name)) {
        item.showValue = '';
        item.value = null;
      }
    });
  }

  handleIptWithSelectedType(selectedType = this.selectedType, setDisabled = true) {
    // selectedType set when orderType change
    // wip: this should handle in setDisabled()
    let toIpt = this.queryPannel.ipts.find(e=>e.name=="to");
    let fromIpt = this.queryPannel.ipts.find(e=>e.name=="from");
    if(selectedType && this.isEditable) {
      // is new order
      switch(selectedType){
        case SELECTEDTYPE.COLORADJUSTMENT:
        case SELECTEDTYPE.ADJUSTMENT:
        case SELECTEDTYPE.ADJUSTMENTHKTHOME:
          // // set all disabled except from when adjustment
          this.queryPannel.ipts.forEach(element => {
            switch(element.title){
              case TITLE.TRANSACTION_TYPE:
                if(this.isDraft){
                  element.disabled = true;
                }else if(setDisabled){element.disabled = false;}
                break;
              case TITLE.FROM:
              case TITLE.REMARK:
                if(setDisabled)element.disabled = false;
                break;
              case TITLE.FROM_STATUS_CONDITION:
              case TITLE.TO:
              case TITLE.TO_STATUS_CONDITION:
                // element value reset by setIptDisabled()
                // element.value = null;
                // element.showValue = null;
                if(setDisabled)element.disabled = true;
                break;
              case TITLE.internalReference:
                if(setDisabled){
                  element.disabled = true
                }
                break;
              default:
                if(setDisabled)element.disabled = true;
            }
          });
          // reset to, tostatuscondition, fromstatuscondition value
          this.toWareHouse = null;
          this.statusItem1 = null;
          this.statusItem2 = null;
          break;
        case SELECTEDTYPE.CHANGECONDITION:
             // disable to and set to as from value
          this.toWareHouse = this.fromChannel;
          if(toIpt){
            if(setDisabled)toIpt.disabled = true;
            toIpt.value = fromIpt.value;
            toIpt.showValue = fromIpt.showValue;
            this.toWareHouse = this.fromChannel;
          }
          break;
        case SELECTEDTYPE.RETURN:
          this.queryPannel.ipts.forEach(element => {
            switch(element.title){
              case TITLE.TRANSACTION_TYPE:
                if(this.isDraft){
                  element.disabled = true;
                }else if(setDisabled){element.disabled = false;}
                break;
              case TITLE.FROM:
              case TITLE.FROM_STATUS_CONDITION:
              case TITLE.TO:
              case TITLE.REMARK:
              case TITLE.NO_OF_CARTON_BOX:
                if(setDisabled)element.disabled = false;
                break;
              case TITLE.TO_STATUS_CONDITION:
                if(setDisabled)element.disabled = true;
                break;
              default:
                if(setDisabled)element.disabled = true;
            }
          });
          break;
        case SELECTEDTYPE.RETURNWOCOURIER:
          this.queryPannel.ipts.forEach(element => {
            switch(element.title){
              case TITLE.TRANSACTION_TYPE:
                if(this.isDraft){
                  element.disabled = true;
                }else if(setDisabled){element.disabled = false;}
                break;
              case TITLE.FROM:
              case TITLE.FROM_STATUS_CONDITION:
              case TITLE.TO:
              case TITLE.REMARK:
              case TITLE.NO_OF_CARTON_BOX:
                if(setDisabled)element.disabled = false;
                break;
              case TITLE.TO_STATUS_CONDITION:
                if(setDisabled)element.disabled = true;
                break;
              default:
                if(setDisabled)element.disabled = true;
            }
          });
          break;
      }
    }
    if(this.selectedType == this.SELECTEDTYPE.RETURNWOCOURIER){
      let appointmentDateIpt = this.queryPannel.ipts.find(ipt=>ipt.title==TITLE.APPOINTMENTDATE)
      appointmentDateIpt.disabled = !this.appointmentDateInputEditable
    }
  }

  handleIptWithSelectedTypeDefault(selectedType = this.selectedType, setDisabled = true) {
    let toIpt = this.queryPannel.ipts.find(e=>e.name=="to");
    let fromIpt = this.queryPannel.ipts.find(e=>e.name=="from");
    if(selectedType && this.isEditable) {
      switch(selectedType){
        case SELECTEDTYPE.CHANNELTRANSFER:
          if(this.ClearDefaultChannel=='N'){
            this.setDefaultChannel(toIpt, this.selectedChannel, this.selectedChannelDisplay,fromIpt)
          }
          break;
        case SELECTEDTYPE.COLORADJUSTMENT:
          if(this.ClearDefaultChannel=='N'){
            this.setDefaultChannel(toIpt, this.selectedChannel, this.selectedChannelDisplay,fromIpt)
          }
          break;
        case SELECTEDTYPE.ADJUSTMENT:
        case SELECTEDTYPE.ADJUSTMENTHKTHOME:
          if(this.ClearDefaultChannel=='N'){
            this.setDefaultChannel(toIpt, this.selectedChannel, this.selectedChannelDisplay,fromIpt)
          }
          break;
        case SELECTEDTYPE.CHANGECONDITION:
          if(this.ClearDefaultChannel=='N'){
            this.setDefaultChannel(toIpt, this.selectedChannel, this.selectedChannelDisplay,fromIpt)
          }
          break;
        case SELECTEDTYPE.RETURN:
          if(this.ClearDefaultChannel=='N'){
            this.setDefaultChannel(toIpt, this.selectedChannel, this.selectedChannelDisplay,fromIpt)
          }
          break;
        case SELECTEDTYPE.RETURNWOCOURIER:
          if(this.ClearDefaultChannel=='N'){
            this.setDefaultChannel(toIpt, this.selectedChannel, this.selectedChannelDisplay,fromIpt)
          }
          break;
      }
    }
  }

  onAdjustmentItemStatusConditionChange(e, data){
    // call api /common/searchItemByChannelIdAndCondition, update row item options by result
    if(!data.fromStockConditionId) {
      data.itemId = null;
    }
  }

  clearTable(){
    this.lineData = [];

  }

  loadAdjustmentItemsByStatusCondition(fromChannel, stockConditionId){
    return this.stockCommonService.getItemsByChannelIdAndCondition(fromChannel, stockConditionId)
  }

  getConditionFromShopAndWarehouseById(id, list:any[] = this.shopAndWarehouse){
    if(id){
      let target = null
      list.forEach(child=>{
        if(child.data == id) {
          target = child;
        }else if(child.children && child.children.length>0){
          let res = this.getConditionFromShopAndWarehouseById(id, child.children)
          if(res) target = res
        }
      })
      return target
    }else{
      return null
    }
  }

  serialModalConfig: any = {
    itemCode: {},
    itemDesc: {},
    actionQty: {},
    qty: 0,
    serialList: [],
    header: 'Input Serial Number',
    header_readonly: 'Serial Number',
    newSerialValue: null,
    range: {},
    index: null,
  }

  editSerial(index) {
    let that = this;
    if(this.loadingSwitch) return;
    if(!this.items || this.items.length == 0) return;
    let data = this.lineData[index];
    let itemList: any[] = this.items;
    let item = itemList.find(e=>e.code==data.itemId)
    let isNegative = data.quantity < 0;
    this.serialModalConfig = {
      ...this.serialModalConfig,
      itemCode: {value: item._data.item, disabled: true, title: 'Item Code'},
      itemDesc: {value: item._data.itemdesc, disabled: true, title: 'Item Desc.'},
      actionQty: {value: `${data.serials?data.serials.length*(isNegative?-1:1):0}`, disabled: true, title: 'Action Qty'},
      isNegative: {value: isNegative?['serialNegative']:[], disabled: !(this.isEditable && this.showCreate), title: 'Negative Qty', name: 'serialNegative', group: 'serialNegative', onChange: (e)=>{
        that.serialPopupIsNegativeChanged(e)
      }},
      qty: data.quantity?data.quantity:0,
      serialList: data.serials?JSON.parse(JSON.stringify(data.serials)):null,
      // header: `Create / Edit Serial`,
      newSerialValue: null,
      range: {},
      index,
      _isNegative: isNegative,
    }
    this.setSerialModalVisible(true)
    // this.lineData[index].serials.push({serialNumber: ''})
  }

  serialPopupIsNegativeChanged(e){
    this.serialModalConfig._isNegative = this.serialModalConfig.isNegative.value.length>0;
    this.serialModalConfig.actionQty.value = Math.abs(this.serialModalConfig.actionQty.value) * (this.serialModalConfig._isNegative?-1:1)
  }

  showSerial(e){
    if(this.loadingSwitch) return;
    this.editSerial(e)
  }

  resetSerialModalConfig(){
    this.serialModalConfig = {
      itemCode: {},
      itemDesc: {},
      actionQty: {},
      qty: 0,
      serialList: [],
      header: 'Input Serial Number',
      header_readonly: 'Serial Number',
      newSerialValue: null,
      range: {},
      index: null,
    }
  }

  setSerialModalVisible(e){
    if(e){this.setSerialList()}
    this.serialModalConfig.visible = e;
  }

  addSerialByInput(e?){
    // this.serialModalConfig.serialList.push({serial: this.serialModalConfig.newSerialValue})
    let success = this.addToSerialList(this.serialModalConfig.newSerialValue, true)
    this.setSerialList()
    let msg = 'Serial Number '+this.serialModalConfig.newSerialValue+' Added'
    // this.showMessage('info', 'Add Success', msg);

    if(success){
      this.showMessage('info', 'Add Success', `Serial Number ${this.serialModalConfig.newSerialValue} added`)
    }else{
      this.showMessage('warn', 'Add Fail', `Serial Number ${this.serialModalConfig.newSerialValue} duplicated`)
    }
    this.serialModalConfig.newSerialValue = null;
  }

  enableScan(e) {
    // console.log(e);
  }

  addSerialByRange(){
    // this.
    let prefix = this.serialModalConfig.range.prefix?this.serialModalConfig.range.prefix:'';
    let suffix = this.serialModalConfig.range.suffix?this.serialModalConfig.range.suffix:'';
    let from = this.serialModalConfig.range.from;
    let to = this.serialModalConfig.range.to;
    let fromInt = Number.parseInt(from, 10);
    let toInt = Number.parseInt(to, 10);
    if (
      from && to &&
      from.length == to.length &&
      !isNaN(fromInt) &&
      !isNaN(toInt) &&
      fromInt <= toInt
    ) {
      let successCount = 0;
      let duplicateCount = 0;
      for( let i = fromInt; i <= toInt; i++ ){
        let value = String(i).padStart(from.length, '0')
        // this.serialModalConfig.serialList.push({serial: `${prefix}${value}${suffix}`})
        let success = this.addToSerialList(`${prefix}${value}${suffix}`)
        if(success){successCount++}else{duplicateCount++}
      }
      this.setSerialList()
      if(successCount) this.showMessage('info', 'Add Success', `${successCount} Serial Number Added`)
      if(duplicateCount) this.showMessage('error', 'Add Fail', `${duplicateCount} Serial Number Duplicated`)
      this.serialModalConfig.range = {}
    }
  }

  serialModalRangeChange(){
    let from = this.serialModalConfig.range.from;
    let to = this.serialModalConfig.range.to;
    let fromInt = Number.parseInt(from, 10)
    let toInt = Number.parseInt(to, 10)
    if (
      from && to &&
      from.length == to.length &&
      !isNaN(fromInt) &&
      !isNaN(toInt) &&
      fromInt <= toInt
    ) {
      this.serialModalConfig.range.total = toInt - fromInt + 1
    }else{
      this.serialModalConfig.range.total = 0
    }
  }

  saveSerial(){
    let idx = this.serialModalConfig.index
    if(idx!=null) this.lineData[idx].serials = JSON.parse(JSON.stringify(this.serialModalConfig.serialList))
    this.lineData[idx].quantity = this.lineData[idx].serials?this.lineData[idx].serials.length*(this.serialModalConfig._isNegative?-1:1):0;
    this.resetSerialModalConfig()
    this.setSerialModalVisible(false)
  }
  updateSerialReceiveStatus(){
    let idx = this.serialModalConfig.index
    if(idx!=null) this.lineData[idx].serials = JSON.parse(JSON.stringify(this.serialModalConfig.serialList))

    this.lineData[idx].qtyActual = this.lineData[idx].serials.filter(serial=>serial.received).length;
    this.lineData[idx].qtyCancelled = this.lineData[idx].serials.filter(serial=>serial.cancelled).length;
    this.lineData[idx].qtyWriteOff = this.lineData[idx].serials.filter(serial=>serial.writeOff).length;

    this.resetSerialModalConfig()
    this.resetSerialReceiveAll()
    this.setSerialModalVisible(false)
  }

  onSerialRowDelete(data){
    this.m.confirm({
      message: `Do you want to delete this serial number?`,
      header: 'Delete Confirmation',
      accept: () => {
        let idx = this.serialModalConfig.serialList.findIndex(e=>data.serial == e.serial);
        (<any[]>this.serialModalConfig.serialList).splice(idx, 1)
        this.setSerialList(true)
        this.serialModalConfig.actionQty = {
          value: `${this.serialModalConfig._isNegative?'-':''}${this.serialModalConfig.serialList.length}`,
          disabled: true, title: 'Action Qty'
        }
      }
    });
  }

  isScannerEnable = false;

  toggleScanner(e){
    this.isScannerEnable = e;
  }
  deviceSelected
  deviceCurrent
  hasPermission
  availableDevices
  hasDevices

  onDeviceChange(device: MediaDeviceInfo) {
    const selectedDevice = device || null;
    if (this.deviceSelected === selectedDevice) { return; }
    this.deviceSelected = selectedDevice;
    this.deviceCurrent = device || undefined;
  }

  onCodeResult(res: string) {
    let duplicateScan = this.serialModalConfig.serialList.some(e=>e.serial==res);
    if(!duplicateScan){
      // this.serialModalConfig.serialList.push({serial: res})
      this.addToSerialList(res, true)
      this.setSerialList()
      this.showMessage('info', 'Scan Success', `Serial Number ${res} added`)
    }
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
  }

  onDeviceSelectChange(selected) {
    const selectedStr = selected.value.deviceId || '';
    const device = this.availableDevices.find(x => x.deviceId === selectedStr);
    this.deviceCurrent = device || null;
  }

  serialFileDropped(e: any){
    let reader = new FileReader();
    e[0].fileEntry.file(file=>{
      reader.readAsText(file)
      reader.onload=()=>{
        let res = (<string>reader.result).split(/\r?\n/).filter(content=>content.length>0);
        let successCount = 0;
        let duplicateCount = 0;
        res.forEach(val=>{
          let success = this.addToSerialList(val)
          if(success){successCount++}else{duplicateCount++}
        });
        this.setSerialList()
        if(successCount) this.showMessage('info', 'Add Success', `${successCount} Serial Number Added`)
        if(duplicateCount) this.showMessage('error', 'Add Fail', `${duplicateCount} Serial Number Duplicated`)
      }
    })
  }

  addToSerialList(e, isUnshift = false){
    let val = e.trim()
    let duplicate = false
    duplicate = this.serialModalConfig.serialList.some(serialItem=>serialItem.serial==val);
    if(!duplicate){
      if(!isUnshift){
        this.serialModalConfig.serialList.push({serial: val})
      }else{
        this.serialModalConfig.serialList.unshift({serial: val})
      }
    }
    this.serialModalConfig.actionQty = {value: `${this.serialModalConfig._isNegative?'-':''}${this.serialModalConfig.serialList.length}`, disabled: true, title: 'Action Qty'}
    return !duplicate;
  }


  checkActionDisable(channelId){
    this.isActionEnable=CommonMethod.haveChannelPermission(channelId)
  }

  routeToEdit(){
    if(!this.userHaveFromChannelAccess){
      this.showMessage('warn','System', 'No permission to edit.')
      return
    }
    this.r.navigate(['main', 'transfer_order', 'edit'], {queryParams: {headId: this.headId}})
  }

  serialModalOnHide(){
    if(this.isScannerEnable){
      this.isScannerEnable = false;
    }
  }

  resetSerialReceiveAll() {
    Object.keys(this.serialReceiveAll).forEach(key=>{
      this.serialReceiveAll[key] = false
    })
  }

  serialReceiveStatusChange(newData, serial, key){
    if(newData){
      this.serialReceiveStatus.forEach(status=>{
        if(status.key!==key){
          serial[status.key] = false;
          this.serialReceiveAll[status.key] = false
        }
      })
    }else{
      this.serialReceiveAll[key] = false
    }
  }
  serialReceiveStatusChangeAll(event, key){
    let checked = event.checked
    let otherStatusKey = this.serialReceiveStatus.map(_e=>_e.key).filter(_e=>_e!=key)
    let otherStatusObj = {}
    if(checked){
      otherStatusKey.forEach(e=>{
        // use for reset row data other key value
        otherStatusObj[e]=false;
        // reset other select all value
        this.serialReceiveAll[e]=false;
      })
    }
    if(this.isCheckingReceived && this.showCreate && checked){
      this._serialList.forEach((data, idx) => {
        if(!data.skipCheckForReceive){
          data[key] = true
          data={...data, ...otherStatusObj}
          this._serialList[idx] = data
        }
      });
    }else{
      this._serialList.forEach((data, idx) => {
        data[key] = false
      })
    }
  }

  // order type options is difference in create / update
  loadOrderTypeOptions(isCreate = false) {
      this.orderTypes = LocalStorageHelper.getObject("ORDER_TYPE").filter((item) => {
      if(this.headId){
        return this.permissionService.havePermission(SEARCH_PERMISSION_CODE[item.id])
        // this.permission.find((el:any) => el.type === item.id
        //   && el.desc.includes('Search')
        // )
      }else{
        return this.permission.find((el:any) => el.type === item.id && el.desc.includes('Create'))
      }
    }).filter((item)=>{
      if(isCreate){
        return item.isManualEntryAllowed=='Y'
      }else{
        return item.isManualUpdateAllowed=='Y'
      }
    }).map((item) => {
      let { value: code, label: name, ...other } = item
      return { code, name, ...other }
    })
  }

// update modal start
  resetUploadModalConfig(){
    this.uploadModalConfig = {
      visible: false,
      title: "Attachment",
      viewOnly: true,
      table: {
        data:[],
        head:[
          {key:'fileName',title:'File Name', width: '8rem',type:"filename" },
          {key:'createDate',title:'Upload Date', width: '8rem',type:'datetime' },
          {key:'delete',title:'Delete', width: '8rem',type:'delete',disabled:true },
        ],
        loading: false,
      }
    }
  }
  showUploadModal(){
    this.resetUploadModalConfig()
    this.loadFileDetails()
    this.setUploadModalVisible(true)
    return this.moduleName='StockOrder'
  }

  showUploadModalByLineId(id){
    this.resetUploadModalConfig()
    this.loadFileDetailsByLineId(id)
    this.setUploadModalVisible(true)
    return this.moduleName='StockOrderLine',this.buttonCountId=id
  }

  hideUploadModal(){
    this.setUploadModalVisible(false)
  }
  setUploadModalVisible(e){
    this.uploadModalConfig.visible=e
  }
  downloadFile(e){
    this.stockCommonService.getEdmsFile(
        e.edmsFileMappingId
      ).subscribe(res=>{
        var url = window.URL.createObjectURL(res);
        var a = document.createElement('a');
        a.href = url;
        a.download = e.fileName;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
      })
  }

  confirmUpload(e, el){
    this.m.confirm({
      message: `Confirm to upload ${e.files[0].name}?`,
      header: 'Submit',
      accept: () => {
        if(this.moduleName=='StockOrder'){
          this.uploadFile(e, el)
        }
        if(this.moduleName=='StockOrderLine'){
          this.uploadFileByStockOrderLine(e, el)
        }
      },
    })
  }

  uploadFile(e, popupPUploadEl){
    if(popupPUploadEl)this.popupPUploadEl = popupPUploadEl;
    this.uploadModalConfig.table.loading = true;
    this.stockCommonService.uploadEdmsFile({
      file:e.files[0],
      body:JSON.stringify({"moduleName":"StockOrder","relatedId":this.headId,"fileName":e.files[0].name})
    }).subscribe(res=>{
      this.loadFileDetails()
      this.resetUploader()
    },err=>{},()=>{
      // this.uploadModalConfig.table.loading = false;
    })
  }

  uploadFileByStockOrderLine(e, popupPUploadEl){
    if(popupPUploadEl)this.popupPUploadEl = popupPUploadEl;
    this.uploadModalConfig.table.loading = true;
      this.stockCommonService.uploadEdmsFile({
        file:e.files[0],
        body:JSON.stringify({"moduleName":"StockOrderLine","relatedId":this.lineData[this.buttonCountId].stockOrderLineId,"fileName":e.files[0].name})
      }).subscribe(res=>{
        this.loadFileDetailsByLineId(this.buttonCountId)
        this.resetUploader()
      },err=>{},()=>{
        // this.uploadModalConfig.table.loading = false;
      })
  }

  loadFileDetailsByLineId(id){
      this.uploadModalConfig.table.loading = true;
      return this.stockCommonService.getEdmsFileIds({
        "moduleName": "StockOrderLine",
        "relatedId": this.lineData[id].stockOrderLineId
      }).subscribe(res=>{
        if(res.code=='000'){
          let data = res.data.sort((a,b)=>{
            return a.createDate>b.createDate?-1:a.createDate<b.createDate?1:0
          })
          this.uploadModalConfig.table.data = data;
          // only below status allow to upload / delete file,
          // other status set viewonly to true
          this.uploadModalConfig.viewOnly = [
            STATUSID.DRAFT,
            STATUSID.ALLOCATED,
            STATUSID.TRANSFERRED,
            STATUSID.RECEIVED,
            STATUSID.ADJ_DRAFT,
            STATUSID.ADJ_REVIEWING,
            STATUSID.ADJ_SUBMITTED
          ].indexOf(this.orderStatusId)==-1;
          let idx = this.uploadModalConfig.table.head.findIndex(head=>head.key=='delete')
          this.uploadModalConfig.table.head[idx].disabled = this.uploadModalConfig.viewOnly;
          this.uploadModalConfig = {...this.uploadModalConfig}
        }
      },err=>{
        this.n.add({severity:'error', summary: 'Error', detail:`An Unexpected Error Occurred, Please Try Again Later. ${err}`})
      },()=>{
        this.uploadModalConfig.table.loading = false;
      })
  }

  loadFileDetails(){
    this.uploadModalConfig.table.loading = true;
    return this.stockCommonService.getEdmsFileIds({
      "moduleName": "StockOrder",
      "relatedId": this.headId
    }).subscribe(res=>{
      if(res.code=='000'){
        let data = res.data.sort((a,b)=>{
          return a.createDate>b.createDate?-1:a.createDate<b.createDate?1:0
        })
        this.uploadModalConfig.table.data = data;
        // only below status allow to upload / delete file,
        // other status set viewonly to true
        this.uploadModalConfig.viewOnly = [
          STATUSID.DRAFT,
          STATUSID.ALLOCATED,
          STATUSID.TRANSFERRED,
          STATUSID.RECEIVED,
          STATUSID.ADJ_DRAFT,
          STATUSID.ADJ_REVIEWING,
          STATUSID.ADJ_SUBMITTED
        ].indexOf(this.orderStatusId)==-1;
        let idx = this.uploadModalConfig.table.head.findIndex(head=>head.key=='delete')
        this.uploadModalConfig.table.head[idx].disabled = this.uploadModalConfig.viewOnly;
        this.uploadModalConfig = {...this.uploadModalConfig}
      }
    },err=>{
      this.n.add({severity:'error', summary: 'Error', detail:`An Unexpected Error Occurred, Please Try Again Later. ${err}`})
    },()=>{
      this.uploadModalConfig.table.loading = false;
    })
  }

  resetUploader(){
    if(this.popupPUploadEl)this.popupPUploadEl.clear()
  }
  onUploadModalHide(){
    this.resetUploader()
    this.resetUploadModalConfig()
  }
  deleteFile(edms){
    this.uploadModalConfig.table.loading = true;
    this.stockCommonService.deleteEdmsFIle([edms.edmsFileMappingId]).subscribe(res=>{
      this.uploadModalConfig.table.loading = false;
      if(this.moduleName=='StockOrder'){
        this.loadFileDetails()
      }

      if(this.moduleName=='StockOrderLine'){
        this.loadFileDetailsByLineId(this.buttonCountId)
      }
    },err=>{},()=>{
      // this.uploadModalConfig.table.loading = false;
    })
  }

  attachmentDeleteOnClick(edms){
    this.m.confirm({
      message: `Confirm to delete ${edms.fileName}?`,
      header: 'Confirmation',
      accept: () => { this.deleteFile(edms) },
    });
  }
// upload modal end


  rejectOnClick(){
    this.m.confirm({
      message: `Confirm to reject?`,
      header: 'Confirmation',
      accept: () => { this.reject() },
    });
  }

 cancelOnClick(){
    this.m.confirm({
      message: `Confirm to cancel?`,
      header: 'Confirmation',
      accept: () => { this.cancelAdj() },
    });
  }
  cancelAdj(){
    if(this.loadingSwitch) return
    this.loadingSwitch++
    let data = {
      orderNumber: this.orderNumber,
      updateType: this.orderTypeItem,
      operation: ORDER_STATUS_UPDATE_OPERATION.CANCELLED,
    }
    this.loadingSwitch++;
    // const btn = this.queryPannel.btns.find(item=>item.tag === PANELBTNTAG.ADJ_REJECT)
    // btn.disabled = true;
    this.setUpdateBtnDisabled(true)
    this.stockOrderService.updateStockOrderStatus(data).subscribe(res=>{
      this.loadingSwitch--;
      if(res.code=='000'){
        this.showMessage('success', this.title, `Cancel Success`)
        this.loadDataByHeadId(this.headId)
      }
    },err=>{
      this.loadingSwitch--;
      this.showMessage('error', this.title, err)
    },()=>{
      // btn.disabled = false;
      this.setUpdateBtnDisabled(false)
      // this.loadingSwitch--;
    })
    this.loadingSwitch--
  }

  reject(){
    if(this.loadingSwitch) return
    this.loadingSwitch++
    let operation = this.selectedType == SELECTEDTYPE.COLORADJUSTMENT? ORDER_STATUS_UPDATE_OPERATION.REJECTED : ORDER_STATUS_UPDATE_OPERATION.REJECT_TO_DRAFT;
    let data = {
      orderNumber: this.orderNumber,
      updateType: this.orderTypeItem,
      operation: operation,
    }
    this.loadingSwitch++;
    // const btn = this.queryPannel.btns.find(item=>item.tag === PANELBTNTAG.ADJ_REJECT)
    // btn.disabled = true;
    this.setUpdateBtnDisabled(true)
    this.stockOrderService.updateStockOrderStatus(data).subscribe(res=>{
      this.loadingSwitch--;
      if(res.code=='000'){
        this.showMessage('success', this.title, `Reject Success`)
        this.loadDataByHeadId(this.headId)
      }
    },err=>{
      this.loadingSwitch--;
      this.showMessage('error', this.title, err)
    },()=>{
      // btn.disabled = false;
      this.setUpdateBtnDisabled(false)
      // this.loadingSwitch--;
    })
    this.loadingSwitch--
  }

  lineReasonChanged(data){
    data.finalReason = data.reason
  }

  getNetAmountForDisplayAndUpdateBtnDisabled(){
    if(!this.showNetAmtPermission) return
    this.loadingSwitch++
    this.setUpdateBtnDisabled(true)
    this.getNetAmountObs().subscribe(res=>{
      this.loadingSwitch--
      this.setNetAmountIptValue(res)
      this.setUpdateBtnDisabled(false)
    }, err=>{
      this.loadingSwitch--
      // this.setUpdateBtnDisabled(false)
      this.showMessage('error','System', err)
    })
  }

  getNetAmountObs(){
    // remove sessionStorage adj_net if line changed
    this.resetSessionNetAmountIfLineChanged();
    let _netAmt = sessionStorage.getItem("adj_net")
    let sessionObj = _netAmt?JSON.parse(_netAmt):null
    if(
      _netAmt == null||
      sessionObj==null||
      sessionObj.orderNumber!=this.orderNumber||
      Number.isNaN(Number.parseFloat(sessionObj.value))
    ){
      // this.loadingSwitch++
      // this.setUpdateBtnDisabled(true)
      return this.stockOrderService.getAdjustmentNetAmount(this.orderNumber).pipe(
        map(res=>{
        // this.loadingSwitch--
        if(res.code == '000'){
          let obj = {
            orderNumber: this.orderNumber,
            value: res.data[0],
            itemAmt: this.lineData.map(line=>{return {qty:line.qty, itemId:line.itemId, conditionId: line.fromStockConditionId}})
          }
          if(obj.value){
            sessionStorage.setItem("adj_net", JSON.stringify(obj))
            let netAmtObj = obj.value
            this.setNetAmountIptValue(netAmtObj)
            // this.setBtnDisabled(false)
            return obj.value
          }
          throw 'An Unexpected Error Occurred'
        }else{
          // this.showMessage('error','System', res.msg)
          throw res.msg
        }
      }))
    }else{
      let netAmtObj = sessionObj.value
      this.setNetAmountIptValue(netAmtObj)
      // this.setBtnDisabled(false)
      return of(sessionObj.value)
    }

    // return of(null).pipe()
  }

  setNetAmountIptValue(netAmtObj){
    let ipt = this.queryPannel.ipts.find(ipt=>ipt.title == TITLE.netAmount)
    let unitValue = []
    // let unit = []
    let unitQty = []
    Object.keys(netAmtObj).forEach((key)=>{
      unitValue.push(`${key} ${netAmtObj[key]}`)
      // unit.push(key)
      unitQty.push(netAmtObj[key])
    })
    this.netAmount = unitValue[0]
    this.netAmtQty = Number.parseFloat(unitQty[0])
    if(ipt){ipt.value = this.netAmount}
    if(this.netAmount!=null){
      this.setUpdateBtnDisabled(false)
    }
  }

  // updateToNextStatusBtn

  setUpdateBtnDisabled(e, force = false){
    let updateToNextStatusBtn = this.queryPannel.btns.find(item=>item.tag === PANELBTNTAG.UPDATETONEXTSTATUS);
    this.setUpdateToNextStatusDisabled(e, force)
    updateToNextStatusBtn.disabled = this.getUpdateToNextStatusDisabled();
  }

  initUpdateBtnDisabled(){
    let updateToNextStatusBtn = this.queryPannel.btns.find(item=>item.tag === PANELBTNTAG.UPDATETONEXTSTATUS);
    updateToNextStatusBtn.disabled = this.getUpdateToNextStatusDisabled();
  }

  resetSessionNetAmountIfLineChanged(){
    let _netAmt = sessionStorage.getItem("adj_net")
    let sessionObj = _netAmt?JSON.parse(_netAmt):null
    if(_netAmt && sessionObj && sessionObj.orderNumber==this.orderNumber){
      let lineQtyAmt = this.lineData.map(line=>{return {qty:line.qty, itemId:line.itemId, conditionId: line.fromStockConditionId}})
      if(sessionObj.itemAmt==null){sessionStorage.removeItem("adj_net"); return}
      // length not same return true / line can't find in session return true
      let lineUpdated = lineQtyAmt.length != sessionObj.itemAmt.length || lineQtyAmt.some(line=>{
        let sessionItemAmt = sessionObj.itemAmt.find(session=>session.itemId == line.itemId && session.qty == line.qty && session.conditionId == line.conditionId)
        return !sessionItemAmt
      })
      if(lineUpdated) sessionStorage.removeItem("adj_net")
    }
  }

  updateOrderDetail(data) {
    // rename orderStatusItem to orderStatusItemObj since this row use for store the object and edit object.value later
    // const orderStatusItemObj = this.queryPannel.ipts.find(item=>item.name==='orderStatusItem')
    const flag = this.validationData()
    if(!flag){
      return
    }
    return this.stockCommonService.updateStockOrder(data).pipe()
    // .subscribe(res=>{
    //   this.loadingSwitch--
    //   if (res.code === '000') {
    //     this.headId = res.data[0].headId;
    //   } else {
    //     if(!res.error){
    //       res.error = res.msg || res.data[0].errorMsg
    //     }
    //     this.showMessage('error','System', res.error)
    //   }
    // },
    // e => {
    //   this.loadingSwitch--
    //   this.showMessage('error','System', e.toString())
    // },
    // () => {
    // })
  }

  getReasonOptions(){
    let reasons = [];
    switch(this.selectedType){
      case SELECTEDTYPE.ADJUSTMENT:
      case SELECTEDTYPE.ADJUSTMENTHKTHOME:
        reasons = this.adjReasons
        break;
      case SELECTEDTYPE.COLORADJUSTMENT:
        reasons = this.colorAdjReasons
        break;
    }
    return reasons.map(reason=>{
      return {
        ...reason,
        displayDesc: `${reason.reasonCode} - ${reason.reasonDescription}`
      }
    })
  }

  initReason(){
    this._reasonOptions = this.getReasonOptions()
    this._reasonOptions.forEach(reason => {
      this.reasonMapping[reason.id] = reason
    });
  }

  _serialList=[]
  _bufferSerialList=[]
  serialFilter=''

  setSerialList(reset = true){
    let serialList = this.serialModalConfig.serialList? this.serialModalConfig.serialList: []
    if(reset){
      this._serialList = serialList
      this._bufferSerialList = JSON.parse(JSON.stringify(serialList))
      this.resetSerialFilter();
    }else{
      this._bufferSerialList = JSON.parse(JSON.stringify(serialList))
      this.serialFilterChanged(this.serialFilter);
    }
  }

  serialFilterChanged(e){
    let filter = e.toLowerCase()
    this._serialList = this._bufferSerialList.filter(serial=>{
      return serial.serial.toLowerCase().indexOf(filter) >= 0
    })
  }
  resetSerialFilter(){
    this.serialFilter = ''
  }

  clearFilter(){
    this.serialFilter = '';
    this.serialFilterChanged(this.serialFilter);
  }

  selectedSerialRow

  serialBulkDelete(){
    if(!this.selectedSerialRow || this.selectedSerialRow.length == 0) return
    // this.onMultiDelete.emit(this.selectedSerialRow)
    let res = this.serialModalConfig.serialList.filter(e=>!this.selectedSerialRow.includes(e))
    this.selectedSerialRow = [];
    this.serialModalConfig.serialList = res;
    this.setSerialList()
    this.serialModalConfig.actionQty = {
      value: `${this.serialModalConfig._isNegative?'-':''}${this.serialModalConfig.serialList.length}`,
      disabled: true, title: 'Action Qty'
    }
  }

  confirmSerialBulkDelete(){
    if(!this.selectedSerialRow || this.selectedSerialRow.length == 0) return
    let totalRemove = this.serialModalConfig.serialList.filter(e=>this.selectedSerialRow.includes(e))
    this.m.confirm({
      message: 'Are you sure to delete selected serial numbers?',
      header: `Delete Serial Number`,
      accept: () => {
        this.serialBulkDelete()
      }
      // rejectLabel: 'Close'
    });
  }

  setDefaultChannel(Toipt, defaultChannel=this.selectedChannel, defaultChannelDisplay=this.selectedChannelDisplay,fromIpt){
  if(Toipt.value == null&&fromIpt.value==null && defaultChannel&&defaultChannel[0]?.id){
      this.fromChannel=defaultChannel[0].id
      fromIpt.value=defaultChannel
      fromIpt.showValue=defaultChannelDisplay
      this.dealItem()
    }
  }

  export() {
    if(this.loadingSwitch) return
    this.loadingSwitch++
    let headerData = [{...this.data, line: null, statusDescription: this.orderStatusObject.name}]
    let headerCol = this.queryPannel.ipts.filter(ipt=>ipt.show!=false).map(ipt=>{return {
      field: ipt._exportField?ipt._exportField:ipt.name,
      title: ipt.title,
      type: ipt.type==INPUT_TYPE.DATETIME?'date':null
    }})
    let tableData = this.data.line.map(line=>{
      return {
        ...line,
        ...line.reason?{
          reasonDesc: this.reasonMapping[line.reason].displayDesc,
          finalReasonDesc: this.reasonMapping[line.reason].displayDesc
        }:{},
        ...line.fromStockConditionId?{
          fromStockConditionDesc: this.conditionMapping[line.fromStockConditionId].description
        }:{}
      }
    })

    let tableCol = this.selectedColumns.map(col=>{return {
      field: col._exportField?col._exportField:col.header,
      title: col.title,
    }})

    let serialData = []
    tableData.forEach(_data => {
      _data.serials.forEach(serial => {
        serialData.push({
          ..._data,
          serials: null,
          ...serial,
        })
      });
    });


    let serialCol = [
      ...tableCol,
      {
        field: 'serial',
        title: 'Serial',
      },
      ...this.isSerialShowReceive?[
        ...this.serialReceiveStatus.map(serialStatus=>{
          return {
            field: serialStatus.key,
            title: serialStatus.label,
          }
        })
      ]:[]
    ]

    CommonMethod.downloadXlsxWithMultiSheetWithOptions(
      [headerData, tableData, serialData],
      [headerCol, tableCol, serialCol],
      [
        'Stock Order Detail',
        'Stock Order Items',
        'Stock Order Item Serials',
      ],
      `Stock_Order_${this.data.orderNumber}`,
      this.datepipe,
    )
    this.loadingSwitch--
  }

  // when status = waiting to complete after any update status api
  handleWaitingToComplete(){
    this.showMessage('info', "", "Waiting for order complete");
    this.loadingSwitch++
    this.allocateCreateTimer = setTimeout(()=>{
      this.getCurrentStatusIsCompleted().subscribe(res=>{
        this.loadingSwitch--
        if(res){
          this.showMessage('success', "", "Order completed");
        }else{
          // loop this function, show msg, loading, call api
          this.handleWaitingToComplete()
        }
      }, err=>{
        this.loadingSwitch--
      })
    },30000)
  }
}

