import {BaseSearchStore} from '../../base/BaseSearchStore'
import {INPUT_TYPE, BUTTON_TYPE, URLDICT, NOTI_TITLE, CONFIG} from '../../base/BaseStore'
import {LocalStorageHelper} from "../../../util/LocalStorageHelper";
import { StockCommonService } from 'src/app/service/stock/stock-common.service';
import { FilterCommonMethod } from 'src/app/util/FilterCommonMethod';
import { StockReservationService } from 'src/app/service/stock/stock-reservation.service';
import { Subject } from 'rxjs';
import { CommonMethod } from 'src/app/util/CommonMethod';
import { FUNCTION_CODE } from '@/service/common/permission.type';
import { ORDERTYPEID, STATUSID } from '@/pages/stock/transfer_order/StockOrderShare';
import {map} from "rxjs/operators";

const BALANCE_BTN_TITLE = {
  CLEAR: 'Clear',
  SEARCH: 'Search',
  SEARCHWITHLIS: 'Search With LIS',
}

export class SearchStore extends BaseSearchStore {

  isSortSearch
  private backendFlag: boolean;
  channelsAccessableByUser;
  disableExport;
  modalConfig: any;
  stockCommonService: StockCommonService
  stockReservationService: StockReservationService
  orderStatus: any[];
  orderType: any[];
  buCodes : any[];
  mfgBrand : any[];

  sourceHeaderNoLinkOrderTypeCodeList

  skuList;
  reservationTypeList: any;
  datepipe

    constructor(
    params,stockCommonService
  ) {
    var queryPannel = {
      ipts: [
        {
          title: "Item Code", // for display, can be chinese or english
          name: "skuNum",  // for database colume
          placeholder: 'Item Code',
          value: [],
          defaultValue: '',
          class: "p-col-12 p-md-6 p-lg-6",
          dataKey: 'code',
          skipAllCheckMatch: true,
          type: INPUT_TYPE.MUTIPLESELECT,
          options: [],
          colspan: 7
        },
        /* {
          title: "Item Description",
          name: 'skuDesc',
          placeholder: 'Item Description',
          value: "",
          class: "p-col-12 p-md-4 p-lg-3",
          colspan: 10,
          type: INPUT_TYPE.INPUT
        }, */
        {
          title: "Channel",
          name: "shopId",
          value: [],
          defaultValue: '',
          selValue: [],
          showValue: null,
          placeholder: 'Choose',
          class: "p-col-12 p-md-6 p-lg-3",
          expandKeys: ['0'],
          type: INPUT_TYPE.MUTIPLETREESELECT,
          options: 'REPOTREE_ACTIVE',
          colspan: 7,
          beforeSubmit: function(value) {
            return value?value.map(val=>{return {id: val.id, key: val.key}}):null },
          change: function(value, ipt) {
            if(Array.isArray(value))
            ipt.showValue = FilterCommonMethod.getRepoMultiSelectShowValue(value);
          }
        },
        {
          title: "Have Avallable stock",
          name: "haveQuantity",
          type: INPUT_TYPE.SELECT,
          options: [
            {code:"All", name: "All"},
            {code:"FG", name: "FG"},
            {code:"Faulty", name: "Faulty"},
            {code:"Transit", name: "Transit"},
            {code:"AVAILABLE_FOR_SALES", name: "Available for Sales"},
          ],
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
          change: (idx, value, ipt) => {
              this.showValueOfReservationWith(value.value)
          }
        },
        {
          title: "condition Type",
          name: "conditionType",
          type: INPUT_TYPE.SELECT,
          disabled: true,
          options: [
            {name: "<", code: 1},
            {name: "<=", code: 2},
            {name: "=", code: 3},
            {name: ">=", code: 4},
            {name: ">", code: 5},
          ],
          class: "p-col-12 p-md-4 p-lg-3",
          value: 4,
        },
        {
          title: "Value of Avallable stock",
          name: "haveQuantityValue",
          type: INPUT_TYPE.INPUT,
          disabled: true,
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
        },
        {
          title: "BU CODE",
          name: 'buCode',
          type: INPUT_TYPE.MUTIPLESELECT,
          options:[],
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
          // optionLabel: 'label',
          // optionValue: 'value',
        },
        {
          title: "Brand",
          name: 'brand',
          type: INPUT_TYPE.MUTIPLESELECT,
          options:[],
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
          // optionLabel: 'label',
          // optionValue: 'value',
        },
        {
          title: "Is Serial Control",
          name: "isSerialControl",
          type: INPUT_TYPE.SELECT,
          disabled: false,
          options: [
            {name: 'Y', code: 'Y'},
            {name: 'N', code: 'N'},
          ],
          class: "p-col-12 p-md-4 p-lg-3",
        },
        {
          type: INPUT_TYPE.EMPTY,
          class: "p-col p-lg-3 p-d-none p-d-lg-block",
        },
        {
          type: INPUT_TYPE.EMPTY,
          class: "p-col p-lg-3 p-d-none p-d-lg-block",
        },
        {
          type: INPUT_TYPE.EMPTY,
          class: "p-col p-lg-3 p-d-none p-d-lg-block",
        },
        {
          title: "Include LIS RT Stock Balance",
          name: "isIncludeLis",
          type: INPUT_TYPE.CHECKBOX,
          class: "p-col-12 p-md-6 p-lg-3",
          group: 'surprise',
          value: null,
          // disabled: true
        },
        {
          title: "Demo Item",
          name: "isDemo",
          type: INPUT_TYPE.CHECKBOX,
          class: "p-col-12 p-md-6 p-lg-3",
          group: 'surprise',
          value: null,
          // disabled: true
        }, {
          name: "excludeLis",
          value: null,
        }
      ],
      btnsclass: "p-d-flex p-justify-end p-col-12 p-md-12 p-lg-6",
      btns: [
        {
          type: BUTTON_TYPE.BUTTON,
          title: BALANCE_BTN_TITLE.CLEAR,
          class: "p-button-outlined p-mr-1",
          icon: '',
          handler: {
            'click': () => {
              this.queryPannel.clear()
              this.pageIndex = 0
              // this.search(this.$URLDICT.STOCK_CURD_SORTSEARCH)
              // this.sortPageSearch(this.$URLDICT.STOCK_CURD_SORTSEARCH, this.field)
            }
          }
        },
        {
          type: BUTTON_TYPE.BUTTON,
          category: 'primary',
          class: "p-mr-1",
          title: BALANCE_BTN_TITLE.SEARCH,
          show: true,
          handler: {
            "click": () => {
              this.doSearch()
            }
          }
        },
        {
          type: BUTTON_TYPE.BUTTON,
          category: 'primary',
          class: "",
          title: BALANCE_BTN_TITLE.SEARCHWITHLIS,
          show: false,
          handler: {
            "click": () => {
              this.doSearchWithLis()
            }
          }
        },
      ]
    }
    var tablePannel = {
      btns: [
        {
          type: BUTTON_TYPE.BUTTON,
          title: 'Export',
          class: "p-button-outlined p-order-0 p-ml-auto p-mr-1",
          disabled: false,
          show: true,
          handler: {
            'click': () => {
              this.excelExport(this.$URLDICT.STOCK_CURD_PRINT)
            }
          }
        }
      ],
      data: [],
      head: [
        // need edit excelExport() if table column edited
        {
          key: "skuDesc",
          title: "Item Description",
          width: "280px",
          minwidth: "100px",
          row: "2",
          sort: true,
        },
        {
          key: "uom",
          title: "UOM",
          width: "80px",
          minwidth: "100px",
          row: "2",
          sort: true,
        },
        {
          key: "total",
          title: "Total Onhand",
          width: "130px",
          minwidth: "130px",
          row: "2",
          sort: true,
          summary:
            "Total FG Qty (i.e. Available for Sales Channel Total + Reserved By Total + Faulty)",
        },
        {
        key: "totalFg",
        title: "Total FG",
        width: "130px",
        minwidth: "130px",
        row: "2",
        sort: true,
        summary:
        "FG Qty (i.e. Available for Sales Channel Total + Reserved By Total)",
        },
        {
          key: "fg",
          title: "Channel Total",
          width: "130px",
          sort: true,
          minwidth: "130px",
          parent: {
            title: "Available for Sales",
            colspan: 2,
            summary: "FG Qty available for new sales or reservation",
          },
          summary:
            "FG Qty (i.e. Total Onhand Qty, less Reserved By Total, less Faulty Qty)",
        },
        {
          key: "crossShopPickQuota",
          title: "Cross-Channel",
          width: "130px",
          minwidth: "130px",
          sort: true,
          parent: true,
          summary:
            "FG Qty quota for cross-channel/shop (i.e. Qty in Channel Total is including Cross-Channel Qty)",
        },
        {
          key: "reservedTotal",
          title: "Total",
          width: "95px",
          minwidth: "95px",
          sort: true,
          parent: {
            title: "Reserved By",
            colspan: 8,
            summary: "Total FG Qty being allocated for all reservations",
          },
          summary:
            "Total FG Qty being allocated (i.e. sum of all reservation types)",
        },
        {
          key: "reserve",
          title: "Allocated",
          width: "120px",
          minwidth: "120px",
          sort: true,
          parent: true,
          onClick: (e) => this.qtyOnClick(e, "reserve"),
          summary: "FG Qty allocated for reservation created  by own channel",
        },
        {
          key: "allocatedAo",
          title: "AO Allocated",
          width: "130px",
          minwidth: "130px",
          sort: true,
          parent: true,
          onClick: (e) => this.qtyOnClick(e, "allocatedAo"),
          summary: "FG Qty allocated for AO order created by own channel",
        },
        {
          key: "allocatedDo",
          title: "DO Allocated",
          width: "130px",
          minwidth: "130px",
          sort: true,
          parent: true,
          onClick: (e) => this.qtyOnClick(e, "allocatedDo"),
          summary: "FG Qty allocated for DO order created by own channel",
        },
        {
          key: "reservedStock",
          title: "Cross-Channel",
          width: "130px",
          minwidth: "130px",
          sort: true,
          parent: true,
          onClick: (e) => this.qtyOnClick(e, "reservedStock"),
          summary:
            "FG Qty allocated for reservations created by cross-channel/shop",
        },
        {
          key: "allocatedStaff",
          title: "Staff",
          width: "130px",
          minwidth: "130px",
          sort: true,
          parent: true,
          onClick: (e) => this.qtyOnClick(e, "allocatedStaff"),
          summary:
            "FG Qty allocated for staff reservation created by own channel",
        },
        {
          key: "transitOut",
          title: "Transit-Out",
          width: "120px",
          minwidth: "120px",
          sort: true,
          parent: true,
          onClick: (e) => this.qtyOnClick(e, "transitOut", "order"),
          summary: "FG Qty allocated for channel transfer/return to warehouse",
        },
        {
          key: "pendingToAdjustment",
          title: "Pending for Adjustment",
          width: "200px",
          minwidth: "200px",
          sort: true,
          parent: true,
          onClick: (e) => this.qtyOnClick(e, "pendingToAdjustment"),
          summary:
            "FG Qty allocated /reserved for stock adjustment (write-off)",
        },
        // // {key: 'faulty', title: 'In-Transit', width: '95px', minwidth: '95px', col: '2' },
        {
          key: "faulty",
          title: "Faulty",
          width: "95px",
          minwidth: "95px",
          sort: true,
          row: "2",
          summary: "Total Faulty Qty",
        },
        {
          key: "totalFaulty",
          title: "Faulty (Total w/o reserved)",
          width: "250px",
          minwidth: "250px",
          sort: true,
          parent: {
          title: "Faulty Reserved By",
          colspan: 3,
          summary: "Total Faulty Qty being allocated for all reservations",
          },
          summary: "Faulty Qty (i.e. Total Onhand Qty, less Reserved By Total)",
        },
        {
          key: "faultyTransitOut",
          title: "Faulty Reserved By (Transit-Out)",
          width: "250px",
          minwidth: "250px",
          sort: true,
          parent: true,
          onClick: (e) => this.qtyOnClick(e, "transitOut", "order"),
          summary: "Faulty Qty allocated for channel transfer/return to warehouse",
        },
        {
          key: "faultyPendingToAdjustment",
          title: "Faulty Reserve By (Adjustment)",
          width: "250px",
          minwidth: "250px",
          sort: true,
          parent: true,
          onClick: (e) => this.qtyOnClick(e, "pendingToAdjustment"),
          summary:
          "Faulty Qty allocated /reserved for stock adjustment (write-off)",
        },
        {
          key: "intransit",
          title: "Transit-In",
          width: "120px",
          minwidth: "120px",
          sort: true,
          row: "2",
          onClick: (e) => this.qtyOnClick(e, "intransit", "order"),
          summary:
            "Total Intra Qty (i.e. Warehouse to channel Qty, Channel to Channel Qty)",
        },
        {
          key: "ao",
          title: "AO Backlog",
          width: "120px",
          minwidth: "120px",
          sort: true,
          parent: {
            title: "Reservation (Pending for Allocation)",
            colspan: 3,
            summary: "Backlog reservation of own channel/shop)",
          },
          onClick: (e) => this.qtyOnClick(e, "ao"),
          summary:
            "Backlog reservation Qty for AO order created by own channel",
        },
        // // {key: 'intransit', title: 'Transit-Out', width: '120px', minwidth: '120px'},
        {
          key: "do",
          title: "DO Backlog",
          width: "120px",
          minwidth: "120px",
          sort: true,
          parent: true,
          onClick: (e) => this.qtyOnClick(e, "do"),
          summary:
            "Backlog reservation Qty for DO order created by own channel",
        },
        {
          key: "staffBacklog",
          title: "By Staff",
          width: "120px",
          minwidth: "120px",
          sort: true,
          parent: true,
          onClick: (e) => this.qtyOnClick(e, "staffBacklog"),
          summary:
            "Backlog reservation Qty for Staff Reservation created by own channel",
        },
      ],
      frozenColumns: [
        {key: 'sourceSystem', title: 'Source System', width: '130px', minwidth: '60px', row: '2', h:'88px', sort:true},
        {key: 'shop', title: 'Channel', width: '100px', row: '2', h:'88px', sort:true},
        {key: 'sku', title: 'Item Code', width: '100px', row: '2', h:'88px', sort:true}
      ],
      frozenWidth: '330px',
      lineCols: [
        // {header:'courier',title:'Courier'},
        {header: 'serial', title: 'Serial'},
        {header: 'stockTypeName', title: 'Stock Status'},
        {header: 'lot', title: 'Lot'},
        //{header:'expiryDate',title:'Expiry Date'},
      ],
      /* selColumns: [
        {key: 'shop', title: 'Channel'},
        {key: 'sku', title: 'Item'},
        {key: 'skuDesc', title: 'Item Description'},
        {key: 'uom', title: 'UOM'},
        {key: 'total', title: 'Total Onhand'},
        {key: 'fg', title: 'Available for Sales By Channel'},
        {key: 'crossShopPickQuota', title: 'Available for Sales By Others'},
        {key: 'reservedTotal', title: 'Reserved By Total'},
        {key: 'reserve', title: 'Reserved By Channel'},
        {key: 'reservedStock', title: 'Reserved By Others'},
        {key: 'faulty', title: 'Faulty'},
        {key: 'ao', title: 'Advance Order (AO)'},
        {key: 'do', title: 'Deposit Order (DO)'},
        {key: 'awaitreturn', title: 'Transit-Out'},
        {key: 'intransit', title: 'Transit-In'}
      ], */
      // detailUrl:[],
      // detailUrl:["main","stock_balance","detail"]
      showCheckboxSwitch: false,
      sortSearchUrl: URLDICT.STOCK_CURD_SORTSEARCH,
    }
    super(queryPannel, tablePannel, params);
    this.stockCommonService = stockCommonService
    this.isSortSearch = true;
    this.changeInitChannel()
    //this.sortPageSearch(this.$URLDICT.STOCK_CURD_SORTSEARCH, this.field).then(() => this.changeTableSize())
    // this.tablePannel['selColumns'] = tablePannel.selColumns
    let userChannelRole = LocalStorageHelper.getObject("REPOMODULEBYUAM")
    this.channelsAccessableByUser = userChannelRole;
    // this.modalConfig = {};
    this.initModalConfig()
    this.popupPaginatorSubject = new Subject();
    this.sourceHeaderNoLinkOrderTypeCodeList=[
      'CHANNEL-TRANSFER',
      'CHANNEL-TRANSFER-COURIER',
      'REPLENISHMENT',
      'RETURN',
      'WAREHOUSE-HOMED',
      'CHANNEL-HOMED',
      'CHANNEL-HOMED-RETURN',
      'WAREHOUSE-HOMED-RETURN'
    ];
    this.skuList = LocalStorageHelper.getObject("SKU").map(sku=>{return {name:`${sku.name}~${sku.flag}`,code:sku.code}});
    let itemIpt = this.queryPannel.ipts.find(ipt=>ipt.name=='skuNum');
    this.reservationTypeList = LocalStorageHelper.getObject('RESSTOCKRESERVATIONTYPE');
    if(itemIpt)itemIpt.options = this.skuList;
    let searchWithLisBtn = this.queryPannel.btns.find(btn=>btn.title==BALANCE_BTN_TITLE.SEARCHWITHLIS)
    searchWithLisBtn.show = this.$COMMONSTORE.permissionService.havePermission(FUNCTION_CODE.STOCK_BALANCE_SEARCH_INCL_LIS)
    this.getItemMasterColumn().subscribe(res=>{
      let buCodeIpt = this.queryPannel.ipts.find(item => item.title === 'BU CODE')
      buCodeIpt.options = this.buCodes
      let brandIpt = this.queryPannel.ipts.find(item => item.title === 'Brand')
      brandIpt.options = this.mfgBrand
    })
  }

  initTablePannel(){
    let that = this;
  }

  // load add item by bu options
  getItemMasterColumn(){
    return this.stockCommonService.getItemMasterColumn().pipe(map(res => {
      if(res.code === '000'){
        // res.data.forEach((item) => {
        //   this.buCodes.push({name: item,  value: item})
        // });
        if(res.data[0]){
          let data = res.data[0];
          this.buCodes = data['buCode']?.filter(e=>e?.length>0).map(e=>{return {name:e,value:e}})
          this.mfgBrand = data['mfgBrand']?.filter(e=>e?.length>0).map(e=>{return {name:e,value:e}})
        }
        return res.data
      }else{
        return null;
      }
    }))
  }

  showValueOfReservationWith(val) {
    let conditionIpt = this.queryPannel.ipts.find(item => item.title === 'condition Type')
    conditionIpt.disabled = val === "All" || !val ? true : false
    conditionIpt.value = 4
    let valueIpt = this.queryPannel.ipts.find(item => item.title === 'Value of Avallable stock')
    valueIpt.value = null
    valueIpt.disabled = val === "All" || !val ? true : false
  }

  changeInitChannel() {
    let item = CommonMethod.getDefaultChannel(LocalStorageHelper.getObject('REPOTREE_ACTIVE'))
    if(!item) return
    this.queryPannel.ipts[1].showValue = item.label
    this.queryPannel.ipts[1].selValue = [item.id]
    this.queryPannel.ipts[1].value = [item]

    // const channels = LocalStorageHelper.getObject('REPOMODULEBYUAMGROUP')
    // if(channels.length>0){
    //   let pranetName = channels[0].label
    //   let selItem = channels[0].items[0]
    //   if(!selItem){
    //     channels.find(item1 => {
    //       if (item1.items.length) {
    //         pranetName = item1.label
    //         selItem = item1.items[0]
    //       }
    //     })
    //   }
    //   const list = this.queryPannel.ipts[1].expandKeys ? this.queryPannel.ipts[1].options[0].children : this.queryPannel.ipts[1].options
    //   const pranetItem = list.find(item1 => item1.label === pranetName)
    //   try{
    //     if(selItem){
    //   const item = pranetItem.children.find(item1 => item1.label === selItem.label)
    //    item.parent = pranetItem
    //   this.queryPannel.ipts[1].showValue = item.label
    //   this.queryPannel.ipts[1].selValue = [item.id]
    //   this.queryPannel.ipts[1].value = [item]
    //   // this.disableExport = false;
    //     }
    // }catch(e){
    //     console.error(e)
    //   }
    //   //
    // }else{
    //   // this.disableExport = true;
    // }
  }

  setExportDisable(){
    // let channelIpt = this.queryPannel.ipts.find(ipt=>ipt.name == "shopId")
    // if(channelIpt && channelIpt.selValue.length > 0){
    //   let accessableChannelIds = this.channelsAccessableByUser.map(channel=>channel.id)
    //   let haveUnaccessableChannelSelected = channelIpt.selValue.some(val=>{
    //     if(accessableChannelIds.indexOf(val) <= -1 ){
    //       return true
    //     }else{
    //       return false
    //     }
    //   })
    //   this.disableExport = haveUnaccessableChannelSelected
    // }else{
    //   this.disableExport = true
    // }

    // let exportBtn = this.tablePannel.btns.find(btn=>btn.title == 'Export')
    // if(exportBtn){
    //   exportBtn.disabled = this.disableExport;
    //   exportBtn.show = !this.disableExport;
    // }
  }

  page(e) {
    // console.log(e);
    if(e==null) return
    this.pageIndex = e.first / e.rows
    this.pageSize = e.rows
    let data: any = {...this.searchParams}
    data.pageIndex = this.pageIndex
    data.pageSize = this.pageSize
    if (this.isSortSearch) this.sortSearch(this.field, this.order)
    // else this.getDataFromServer(this.$URLDICT.STOCK_CURD_SORTSEARCH, 0, data).then()
  }

  excelExport(u) {
    let params = JSON.parse(JSON.stringify(this.lastSuccessSearchPayload));
    // api require pagination, set pagesize to 2.14b
    params.pageIndex = 0
    params.pageSize = CONFIG.XLSX_ROW_LIMIT - 2 // th
    this.stockCommonService.searchSortBalance(params).subscribe(res=>{
      if(res){
        CommonMethod.downloadXlsxWithOptions(
          res,
          [...this.tablePannel.frozenColumns, ...this.tablePannel.head],
          `Stock_Balance_Enquiry_${( this.datepipe.transform(new Date(), 'yyyyMMddHHmmss'))}`,
          this.datepipe,
          {haveParent:true, fieldKey: 'key'}
        )
      }
    })
  }

  setStockCommonService(stockCommonService){
    this.stockCommonService = stockCommonService;
  }
  setStockReservationService(e){
    this.stockReservationService = e;
  }

  s2ab(s) {
    var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    var view = new Uint8Array(buf);  //create uint8array as viewer
    for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return buf;
  }

  convertData(data){
    if(data.shopId){
      let shopIdIpt = this.queryPannel.ipts.find(ipt=>ipt.name == 'shopId')
      data.shopId = shopIdIpt.beforeSubmit?shopIdIpt.beforeSubmit(shopIdIpt.value): shopIdIpt.value;
    }
    if(data.repoId){
      data.repoId = data.repoId.data || data.repoId
    }
    if(data.channelId){
      data.channelId = data.channelId.data || data.channelId
    }
    if(data.skuId){
      data.skuId = location.pathname.includes('stock_replenishment') ? data.skuId.data : data.skuId.map(item => item.code);
    }
    if(data.eventType){
      data.eventType = data.eventType.code || data.eventType
    }
    if(data.fillStatus){
      data.fillStatus = data.fillStatus.code || data.fillStatus
    }
    if(data.skuNum){
      data.skuNum = data.skuNum.map(item =>{return item.code});

      const skuNum = data.skuNum.map(item => {
        const skus = LocalStorageHelper.getObject('SKU')
        const sku = skus.find(sub => sub.code === item)
        sku.name = sku.name.split(" ~ ")[0]
        const {code:id, name:code} = sku
        return {id, code}
      });
      data.skuNum = skuNum
    }
    if(data.orderStatusCode){
      data.orderStatusCode = this.queryPannel.ipts[2].options.find(item => item.code === data.orderStatusCode).alias;
    }
    /* if(data.createAt){
      data.createAt[0] = CommonMethod.dateToTime(data.createAt[0]);
      data.createAt[1] = CommonMethod.dateToTime(data.createAt[1]);
    } */

    return data
  }

  sortPN(e){
    if(this.field == e.field && this.order == e.order) {return}else{
      this.field = e.field;
      this.order = e.order;
      // this.pageIndex = e.order - 1;
      this.sortPageSearch(this.$URLDICT.STOCK_CURD_SORTSEARCH, this.field)
    }
  }

  setModalVisible(e) {
    this.modalConfig.visible = e;
  }

  qtyOnClick(rowData, columnKey, type = 'reservation') {
    if(!this.permissions.includes('Pop-up allocated on Balance Enquiry')) {
      this.showMessage('error', NOTI_TITLE, 'No permission')
      return
    }
    // let [rowData, columnKey] = arg
    this.modalConfig.type = type;
    let table = this.modalConfig.tables[this.modalConfig.type]
    table.data = [];
    table.loadingSwitch = true;
    this.modalConfig._data = rowData;
    this.modalConfig.selectedColumn = this.tablePannel.head.find(head=>head.key == columnKey)
    this.modalConfig.title = `${rowData.shop} | ${rowData.sku} | ${rowData.skuDesc}`
    this.modalConfig.visible = true;
    let searchParams: any = {}
    switch(type){
      case 'reservation':
        searchParams = {
          pageIndex: table.pageIndex,
          pageSize: table.pageSize,
          sortEvent: {field: table.field, order: table.order},
          channel: [rowData.repoId],
          itemId: [rowData.skuId],
          accountName: this.$COMMONSTORE.getAccount(),
        }
        break;
      case 'order':
        searchParams = {
          pageIndex: table.pageIndex,
          pageSize: table.pageSize,
          sortEvent: {field: table.field, order: table.order},
          item: rowData.skuId,
          // createAt: 0,
          accountName: this.$COMMONSTORE.getAccount(),
        }
        break;
    }
    switch(columnKey){
      case 'reserve':
        let filterTypeList = this.reservationTypeList
          .filter(type=>[2,3,5,6,9,12,11,16].indexOf(type.id)<0)
          .map(type=>type.stockReservationTypeCode)
        searchParams = {
          ...searchParams,
          reservationType: [...filterTypeList],
          haveQuantity: "Allocated",
          salesEqualFlag: "1",
        }
        break;
      case 'ao':
        searchParams = {
          ...searchParams,
          reservationType: ["SOAO","PPOS - Full Payment","BYOD-AO","NOP - AO"],
          haveQuantity: "Pending",
        }
        break;
      case 'do':
        searchParams = {
          ...searchParams,
          reservationType: ["SODO"],
          haveQuantity: "Pending",
        }
        break;
      case 'allocatedAo':
        searchParams = {
          ...searchParams,
          reservationType: ["SOAO","PPOS - Full Payment","BYOD-AO","NOP - AO"],
          haveQuantity: "Allocated",
          salesEqualFlag: "1",
        }
        break;
      case 'allocatedDo':
        searchParams = {
          ...searchParams,
          reservationType: ["SODO"],
          haveQuantity: "Allocated",
          salesEqualFlag: "1",
        }
        break;
      case 'allocatedStaff':
        searchParams = {
          ...searchParams,
          reservationType: ["ST"],
          haveQuantity: "Allocated",
          salesEqualFlag: "1",
        }
        break;
      case 'staffBacklog':
        searchParams = {
          ...searchParams,
          reservationType: ["ST"],
          haveQuantity: "Pending",
        }
        break;
      case 'reservedStock':
        searchParams = {
          ...searchParams,
          // reservationType: ["SOAO"],
          haveQuantity: "Allocated",
          salesNotEqualFlag: "1",
        }
        break;
      case 'transitOut':
        searchParams = {
          ...searchParams,
          fromChannelId: rowData.repoId,
          orderTypeIdList: [2,3,5,16,25],
          orderStatusCodeList: ['Confirmed'],
        }
        break;
      case 'pendingToAdjustment':
        searchParams = {
          ...searchParams,
          reservationType: ["Adjustment"],
          haveQuantity: "Allocated",
          salesEqualFlag: "1",
        }
        break;
      // faulty can't find faulty order,
      // CHANGE-CONDITION order only have 2 status draft, complete, get complete order will get all history condition order,
      // can't know which faulty item in which order send back to warehouse / repaired
      // case 'faulty':
      //   searchParams = {
      //     ...searchParams,
      //     // reservationType: ["SOAO"],
      //     toStatusNature: 1002,
      //     // orderTypeIdList: [2,3,4,5,10,14,15,16,20,21],
      //     orderStatusCodeList: ['Transferred', "Received for Inspection"],
      //   }
      //   break;
      case 'intransit':
        searchParams = {
          ...searchParams,
          // reservationType: ["SOAO"],
          toChannelId: rowData.repoId,
          // orderTypeIdList: [5,16,4,20], // Channel to Channel By Staff, Channel to Channel By Courier, Warehouse to Channel, Channel HomeD Return
          orderStatusCodeList: ["Received for Inspection", "Transferred", "Waiting to Complete"],
        }
        break;
    }
    this.popupTableSearch(searchParams, type)
    // this.stockReservationService.searchReservation(searchParams).subscribe(res=>{
    //   if(res) table.data = res.data;
    //   this.modalConfig.tables[this.modalConfig.type].loadingSwitch = false;
    // }, err=>{
    //   console.error(err)
    //   this.modalConfig.tables[this.modalConfig.type].loadingSwitch = false;
    // })

  }

  initModalConfig(){
    this.modalConfig = {};
    let reservationTable: any = {
      columns: [
        {header: 'reservationDate', title: 'Reservation Date', width: '150px', type: 'date'},
        {header: 'sourceTxnRefHeaderNo', title: 'Source Ref. No.', width: '150px', type:'link', isClickableKey:'_sourceRefNoLink', onClick:($e,data)=>{
          $e.stopPropagation();
          try{
            let _id = data.sourceTxnRefHeaderId
            let sourceTxnRefHeaderURL = data.sourceTxnRefHeaderURL
            if(_id && _id.length > 0) {
              let url = this.getCOMUrl(_id, sourceTxnRefHeaderURL)
              if(url) window.open(url, '_blank')
            }
          }catch(e){console.error(e)}
        }},
        {header: 'reservationType', title: 'Reservation Type', width: '150px'},
        {header: 'salesChannel', title: 'Sales Channel', width: '150px'},
        {header: 'qtyRequested', title: 'Request Reserve Qty', width: '170px'},
        {header: 'qtyPending', title: 'Pending Qty', width: '120px'},
        {header: 'qtyAllocated', title: 'Allocate Qty', width: '120px'},
        {header: 'qtyReleased', title: 'Release Qty', width: '120px'},
        {header: 'qtyCancelled', title: 'Cancel Qty', width: '120px'},
        {header: 'expiryDate', title: 'Expiry Date', width: '130px', type: 'date'},
        {header: 'extensionCount', title: 'No. of Extension', width: '150px'},
        {header: 'sensitiveRemarks', title: 'Remarks', width: '150px'},
        {header: 'reservedBy', title: 'Reserved By', width: '120px'},
        {header: 'id', title: 'Reservation ID', width: '150px'},
        {header: 'sourceSystem', title: 'Source System', width: '140px'},
        {header: 'sourceTxnType', title: 'Source System Txn Type', width: '190px'},

        // {header: 'action', title: 'Action', type: 'action'},
        // {header: 'itemCode', title: 'Item', width: '90px'},
        // {header: 'itemDesc', title: 'Description', width: '250px'},
        // {header: 'releaseChannel', title: 'Stock Channel', width: '150px'},
        // {header: 'reason', title: 'Reason', width: '100px'},
        // {header: 'reserveDescription', title: 'Reserved For', width: '190px'},
        // {header: 'schNum', title: 'School Number', width: '150px'},
        // {header: 'sourceTxnRefHeaderNo', title: 'Source Ref. No', width: '150px'},
        // {header: 'schLocCode', title: 'School Location Code', width: '190px'},
      ],
      selectedColumns: [],
      frozenColumns: [
        {header: 'expandControl', title: '', type: 'expandControl', width: '56px', hideInExpandRow: true, sortable: false},
      ],
      frozenWidth: '56px',
      data: [],
      initSortField: 'reservationDate',
      initSortOrder: -1,
      field: 'reservationDate',
      order: -1,
      totalRecords: null,
      pageIndex: 0,
      pageSize: 20,
      searchParams: {},
      loadingSwitch: false,
      resizable: true,
      _data: null,
      selectedColumn: null,
      page: (e)=>{this.popupTablePageChange(e)},
      sortSearch: (e)=>{this.popupTableSortSearch(e, -1)}
    }
    reservationTable = {
      ...reservationTable,
      selectedColumns: reservationTable.columns,
    }
    let orderTable: any = {
      columns: [
        {header: 'orderType', title: 'Transaction Type', width: '200px'},
        {header: 'orderNumber', title: 'Stock Order No.', width: '140px'},
        {header: 'from', title: 'From', width: '120px'},
        {header: 'to', title: 'To', width: '120px'},
        {header: 'status', title: 'Stock Order Status', width: '160px'},
        {header: 'quantity', title: 'Qty', width: '120px'},
        {header: 'sourceRefNo', title: 'Source Ref. No.', width: '260px', type:'link', isClickableKey:'_sourceRefNoLink', onClick:($e,data)=>{$e.stopPropagation();this.onDnRefNoClick(data)}},
        {header: 'creationDate', title: 'Creation Date', width: '140px', type: 'date'}
      ],
      selectedColumns: [],
      frozenColumns: null,
      frozenWidth: null,
      data: [],
      initSortField: 'creationDate',
      initSortOrder: -1,
      field: 'creationDate',
      order: -1,
      totalRecords: null,
      pageIndex: 0,
      pageSize: 20,
      searchParams: {},
      loadingSwitch: false,
      resizable: true,
      _data: null,
      // selectedColumn: null,
      page: (e)=>{this.popupTablePageChange(e)},
      sortSearch: (e)=>{this.popupTableSortSearch(e, -1)}
    }
    orderTable = {
      ...orderTable,
      selectedColumns: orderTable.columns,
    }
    this.orderStatus = LocalStorageHelper.getObject("ORDER_STATUS")
    this.orderType = LocalStorageHelper.getObject("STOCK_TYPE")
    this.modalConfig = {
      tables: {
        'reservation': reservationTable,
        'order': orderTable,
      },
      visible: false,
      title: '',
      type: 'reservation'
    }
    this.modalConfig = {...this.modalConfig}

  }

  popupTablePageChange(e){
    if(e==null)return
    if(this.modalConfig.tables[this.modalConfig.type].pageSize != e.rows){
      this.modalConfig.tables[this.modalConfig.type].pageIndex = 0
      this.modalConfig.tables[this.modalConfig.type].pageSize = e.rows;
      this.popupPaginatorSubject.next('reset')
    }else{
      this.modalConfig.tables[this.modalConfig.type].pageIndex = e.page;
    }
    this.popupTableSearch({
      ...this.modalConfig.tables[this.modalConfig.type].searchParams,
      pageIndex: this.modalConfig.tables[this.modalConfig.type].pageIndex,
      pageSize: this.modalConfig.tables[this.modalConfig.type].pageSize,
    })
  }

  popupTableSortSearch(key, _order) {
    let table = this.modalConfig.tables[this.modalConfig.type];
    let field = table.field;
    let order = table.order;
    if(field==key && _order!=null && order == _order) return
    this.modalConfig.tables[this.modalConfig.type].field = key
    this.modalConfig.tables[this.modalConfig.type].order = _order
    this.popupTableSearch({
      ...this.modalConfig.tables[this.modalConfig.type].searchParams,
      sortEvent: {field: this.modalConfig.tables[this.modalConfig.type].field, order: this.modalConfig.tables[this.modalConfig.type].order}
    })
  }
  popupTableSearch(e, type = this.modalConfig.type){
    this.modalConfig.tables[this.modalConfig.type].searchParams = e;
    let table = this.modalConfig.tables[this.modalConfig.type];
    let searchService:Function
    switch(type){
      case 'reservation':
        searchService = this.stockReservationService.searchReservation
        break;
      case 'order':
        searchService = this.stockCommonService.getTransferOrderByItem
        break;
    }
    this.modalConfig.tables[this.modalConfig.type].loadingSwitch = true
    searchService(e).subscribe(res=>{
      this.modalConfig.tables[this.modalConfig.type].loadingSwitch = false
      if(res) {
        table.searchParams = e;
        let data = res.data
        data = this.preProcessData(data, type)
        table.data = data;
        table.totalRecords = res.msg
      }
      table.loadingSwitch = false;
    }, err=>{
      this.modalConfig.tables[this.modalConfig.type].loadingSwitch = false
      console.error(err)
      table.loadingSwitch = false;
    })
  }

  exportPopupTable(){

    let params = JSON.parse(JSON.stringify(this.modalConfig.tables[this.modalConfig.type].searchParams));
    // api require pagination, set pagesize to 2.14b
    params.pageIndex = 0
    params.pageSize = 2147483647
    let searchService:Function
    switch(this.modalConfig.type){
      case 'reservation':
        searchService = this.stockReservationService.searchReservation
        break;
      case 'order':
        searchService = this.stockCommonService.getTransferOrderByItem
        break;
    }
    searchService(params).subscribe(res=>{
      let resData = res.data
      if(resData){
        CommonMethod.downloadXlsxWithOptions(
          resData,
          [...this.modalConfig.tables[this.modalConfig.type].columns],
          `Stock_Balance_Enquiry_${this.modalConfig._data.shop}_${this.modalConfig._data.sku}_${this.modalConfig.selectedColumn.key}_${( this.datepipe.transform(new Date(), 'yyyyMMddHHmmss'))}`,
          this.datepipe,
          {haveParent:false, fieldKey: 'header'}
        )
      }
    })
  }

  popupPaginatorSubject: Subject<any>;

  onDnRefNoClick(e){
    this.stockCommonService.getDmDeliveryDoc({orderId:e.headId}).subscribe(res=>{
      if(res.code=='000'){
        // let blob = this.base64toBlob(res.data[0].fileBase64)
        // this.saveData(blob,`DM_Delivery_Note_${e.sourceRefNo}.pdf`)
        let blob = CommonMethod.base64toBlob(res.data[0].fileBase64)
        CommonMethod.saveBlobData(blob,`DM_Delivery_Note_${e.sourceRefNo}.pdf`)
        // window.open(url);
      }else{
        this.showMessage('error', NOTI_TITLE, res.message || res.msg)
      }
    },err=>{
      this.showMessage('error', NOTI_TITLE, 'An Unexpected Error Occurred')
    },()=>{
    })
  }

  getCOMUrl(comId: string, url: string){
    // let url: string = CONFIG.RESERVATION_COM_URL;
    // let result = url.replace('${1}', comId)
    // return result
    if(!url||!comId){
      return null
    }
    return `${url}/${comId}`
  }

  preProcessData(data, type){
    data.forEach(e => {
      if(type=='order')
      e._sourceRefNoLink = (this.sourceHeaderNoLinkOrderTypeCodeList.indexOf(e.orderTypeName)>-1);
      if(type=='reservation')
      e._sourceRefNoLink = this.getSourceTxnRefHeaderIdLink(e)
    });
    return data
  }

  getSourceTxnRefHeaderIdLink(item:any){
    return item.sourceTxnRefHeaderURL!=null&&item.sourceTxnRefHeaderURL.length>0&&item.sourceTxnRefHeaderId!=null&&item.sourceTxnRefHeaderId.length>0
    // if(sourceSystem){return this._allowListBuffer['_sourceTxnRefHeaderIdLink'].indexOf(sourceSystem.toUpperCase())>-1}else{return false}
  }

  validator(){
    let channelIpt = this.queryPannel.ipts.find(ipt=>ipt.name == "shopId");
    let itemIpt = this.queryPannel.ipts.find(ipt=>ipt.name == "skuNum");
    let channel = channelIpt.value;
    let item = itemIpt.value;
    if(!(channel?.length>0||item?.length>0)){
      this.showMessage('warn', NOTI_TITLE, 'Item or Channel is required!')
      return false
    }
    return true
  }

  doSearch(){
    let valid = this.validator()
    if(!valid) return
    this.pageIndex = 0
    let excludeLisIpt = this.queryPannel.ipts.find(ipt=>ipt.name == "excludeLis");
    excludeLisIpt.value = 1;
    this.sortPageSearch(this.$URLDICT.STOCK_CURD_SORTSEARCH, this.field).then(res=>{
      if(this.paginatorSubject) this.paginatorSubject.next('reset');
    })
    // this.setExportDisable()
  }
  doSearchWithLis(){
    let valid = this.validator()
    if(!valid) return
    this.pageIndex = 0
    let excludeLisIpt = this.queryPannel.ipts.find(ipt=>ipt.name == "excludeLis");
    excludeLisIpt.value = null;
    this.sortPageSearch(this.$URLDICT.STOCK_CURD_SORTSEARCH, this.field).then(res=>{
      if(this.paginatorSubject) this.paginatorSubject.next('reset');
    })
    // this.setExportDisable()
  }
}
